// Third party libraries
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Prompt } from 'react-router-dom';
import { isEqual } from 'lodash';
import { connectSocket, disconnectSocket } from '../../context/socket';
import { nanoid } from 'nanoid';
import * as immutable from 'object-path-immutable';
// Components
import { BtnSubmit } from '../btn-submit/Btn-submit';
import { DepartmentLabel } from '../department-label/Department-label';
import { DepartmentLabelsec } from '../department-labelsec/Department-labelsec';
import { FormContainer } from '../form-container/Form-container';
import { LongDistanceRadiobuttons } from '../longdistance-radiobuttons/LongDistance-radiobuttons';
import { LongDistanceTitle } from '../longdistance-title/LongDistance-title';
import { LongDistanceMilesRangeInput } from '../long-distance-miles-range-input/Long-distance-miles-range-input';
import { LongDistanceTypeRadiobuttons } from '../longdistanceType-radiobuttons/LongDistanceType-radiobuttons';
import { LongDistanceLabel } from '../longdistance-label/LongDistance-label';
import { LongDistanceLabelsec } from '../longdistance-labelsec/LongDistance-labelsec';
import { Icon } from '../Icon';
import { SectionTitle } from '../section-title/Section-title';
// Local State Functions
import { getUserId, getCompanyId } from '../../clientStore/authSlice/auth-slice';
import {
  getOutOfStateSavingStatus,
  setOutOfStateSavingStatus,
  setOutOfStateLoadingStatus,
} from '../../clientStore/outofstateSlice/outofstate-slice';
import {
  saveOutOfState,
  updateOutOfState,
} from '../../clientStore/outofstateSlice/outofstate-async-thunk';
import {
  getOutOfStates,
  setOutOfStatesLoadingStatus,
} from '../../clientStore/outofstatesSlice/outofstates-slice';
// DataModel
import { outofstateDataModel } from '../../models/outofstate';
// Utils
import { fetchedData } from '../../util/utils';
import { IconNames } from '../../util/const';
//Styles
import styles from './OutOfStateForm.module.css';
import { isOutOfStateFormValid } from '../../util/formValidations';
import { getCompany, getCompanyLoadingStatus } from '../../clientStore/companySlice/company-slice';

export const OutOfStateForm = ({ outofstateData, formType = 'new', outofstateId = undefined }) => {
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [lastTarget, setLastTarget] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const userId = useSelector(getUserId);
  const companyId = useSelector(getCompanyId);
  const savedStatus = useSelector(getOutOfStateSavingStatus);
  const outofstates = useSelector(getOutOfStates);
  var outofstate = outofstateData ? outofstateData : outofstateDataModel;
  const companyLoadingStatus = useSelector(getCompanyLoadingStatus);
  const company = useSelector(getCompany);
  if (company &&
    Object.keys(company).length &&
    company.OOSCalcMethod
  ) {
    outofstate.calcMethod = company.OOSCalcMethod;
  }

  const [formValue, setFormValue] = useState({
    ...outofstate,
    authorId: userId,
    version: outofstate.version + 1,
    companyId,
  });

  const unChangedFormValue = {
    ...outofstate,
    authorId: userId,
    version: outofstate.version + 1,
    companyId,
  }

  const renderOutOfStatesOptions = () => {
    if (outofstates.length > 0) {
      return (
        outofstates.map((outofstate) => {
          return (
            <option value={outofstate._id} key={nanoid(outofstates.length)}>
              {outofstate.directionName}
            </option>
          );
        })
      );
    }
    return ('');
  };

  const onChangeSameAsSelect = async (evt) => {
    const copiedOutOfState = await fetchedData('outofstate/getOutOfState', 'POST', { id: evt.target.value });
    if (copiedOutOfState.status === 200) {
      setFormValue({
        ...copiedOutOfState.data,
        _id: outofstate._id ? outofstate._id : undefined,
        authorId: userId,
        version: outofstate.version + 1,
        companyId,
        directionName: outofstate.directionName,
      });
    }
  }


  const formSubmit = (evt) => {
    evt.preventDefault();
    setIsFormDirty(false)
    if (isOutOfStateFormValid(formValue)) {
      switch (formType) {
        case 'new':
          dispatch(saveOutOfState(formValue));
          break;
        case 'edit':
          dispatch(updateOutOfState(formValue, outofstateId));
          break;
        default:
          throw new Error('Invalid form type');
      }

      setFormValue({
        ...outofstateDataModel,
        authorId: userId,
        companyId,
      });
    }
    else {
      alert("Please fill all fields")
    }
  };

  useEffect(() => {
    if (savedStatus === 'successed') {
      dispatch(setOutOfStateSavingStatus({ status: 'idle' }));
      dispatch(setOutOfStateLoadingStatus({ status: 'idle' }));
      dispatch(setOutOfStatesLoadingStatus({ status: 'idle' }));
      setIsFormDirty(false)
      history.replace('/outofstates');
    }
  }, [savedStatus, history, dispatch]);

  const onFormValueChange = (path, method, value) => {
    setFormValue(immutable[method](formValue, path, value));
  };

  const clearLastTarget = () => {
    if (lastTarget) {
      setLastTarget(undefined);
    }
  }

  const onChangeCalcMethod = (evt) => {
    const typeValue = evt.target.value === 'true' ? 'discount' : 'deduct';
    onFormValueChange('calcMethod', 'set', typeValue);
  };

  const setParameterValue = (evt, index) => {
    onFormValueChange(`parameters.${index}.${evt.target.name}`, 'set', evt.target.value);
  }

  const setAmountValue = (evt, index) => {
    onFormValueChange(`extraAmounts.${index}.${evt.target.name}`, 'set', evt.target.value);
  }

  const addParameter = (index) => {
    const newAmount = {
      [`pName${index}`]: '',
      [`pAmount${index}`]: '',
    }
    onFormValueChange(`parameters`, 'push', newAmount);
  }

  const removeParameter = () => {
    onFormValueChange(`parameters.${formValue.parameters.length - 1}`, 'del');
  }

  const addAmount = (index) => {
    const newAmount = {
      [`amountName${index}`]: '',
      [`extraAmount${index}`]: '',
      [`type${index}`]: 'perJob'
    }
    onFormValueChange(`extraAmounts`, 'push', newAmount);
  }

  const removeAmount = () => {
    onFormValueChange(`extraAmounts.${formValue.extraAmounts.length - 1}`, 'del');
  }

  const addRange = (index) => {
    const newRange = {
      [`rangeStart${index}`]: '',
      [`rangeEnd${index}`]: '',
      [`days${index}`]: ''
    }
    onFormValueChange(`driveTimes`, 'push', newRange);
  }

  const removeRange = () => {
    onFormValueChange(`driveTimes.${formValue.driveTimes.length - 1}`, 'del');
  }

  useEffect(() => {
    setIsFormDirty((savedStatus !== 'successed' && savedStatus !== 'loading') && !isEqual(formValue, unChangedFormValue))
  }, [formValue, savedStatus]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isFormDirty) {
        e.preventDefault();
        e.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
        disconnectSocket()
        connectSocket()
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isFormDirty]);

  return (
    <FormContainer>
      <Prompt
        when={isFormDirty}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
      <form className={styles.form}>
        <section>
          <div className={styles.directionName}>
            <DepartmentLabelsec
              inputId={'directionName'}
              name={'directionName'}
              title={'Direction name'}
              placeholder={'For example: LA-SF'}
              value={formValue.directionName}
              changeValue={(evt) => onFormValueChange(`directionName`, 'set', evt.target.value)}
            />
            <div style={{ position: "relative", marginLeft: "150px" }}>
              <label htmlFor='sameAs' className={styles.fieldName}>Same as</label>
              <select
                id='sameAs'
                className={styles.departmentField}
                onFocus={clearLastTarget}
                onChange={onChangeSameAsSelect}
              >
                <option>None</option>
                {renderOutOfStatesOptions()}
              </select>
            </div>
          </div>
        </section>
        <section className={styles.allPage}>
          <div className={styles.titleContainer}>
            <LongDistanceTitle title={'Out of state'} />
          </div>
          <div className={styles.calcMethContainer}>
            <LongDistanceRadiobuttons
              title={'Calculation method'}
              name={'calcMethod'}
              isChecked={formValue.calcMethod === 'discount'}
              onChangeValue={onChangeCalcMethod}
              firstValue={'cash discount % from total'}
              secondValue={'deduct $ for cash payment'}
            />

            <div className={styles.cashPaymentContainer}>
              {(formValue.calcMethod === 'discount') ?
                <LongDistanceLabelsec
                  title="Discount for CASH PAYMENT "
                  placeholder="ex: 5"
                  name='cashPaymentDiscount'
                  value={formValue.cashPaymentDiscount}
                  changeValue={(evt) => onFormValueChange(`${evt.target.name}`, 'set', evt.target.value)}
                  valueType={'%'}
                  inputId='cashPaymentDiscount'
                  inputType='number'
                /> :
                <LongDistanceLabelsec
                  title="Deduct $ for CASH PAYMENT"
                  placeholder="ex: 200"
                  name='cashPaymentDeduct'
                  value={formValue.cashPaymentDeduct}
                  changeValue={(evt) => onFormValueChange(`${evt.target.name}`, 'set', evt.target.value)}
                  valueType={'$'}
                  inputId='packingRD'
                  inputType='number'
                />
              }
            </div>
          </div>
          <SectionTitle title={'Factor'} />
          <div className={styles.distanceContainer}>
            <div className={styles.parametersContainer}>
              {formValue.parameters.map((parameter, index) => {
                return (
                  <div className={styles.container} key={index + "eo"}>
                    <div className={styles.shuttleContainer}>
                      <label
                        className={styles.inputContainer}
                        htmlFor={`parameter_${index + 1}_parameterName`}
                      >
                        <p className={styles.inputName1}>Name</p>
                        <input
                          id={`parameter_${index + 1}_parameterName`}
                          className={styles.input}
                          type='text'
                          placeholder=""
                          name={`pName${index}`}
                          value={parameter[`pName${index}`]}
                          onChange={(evt) => setParameterValue(evt, index)}
                          required
                        />
                      </label>
                      <label
                        className={styles.inputContainerSecond}
                        htmlFor={`parameter_${index + 1}_p`}
                      >
                        <p className={styles.inputNameSecond}>amount</p>
                        <span className={styles.inputValueType}>$</span>
                        <input
                          id={`parameter_${index + 1}_p`}
                          className={styles.inputSecond}
                          type='number'
                          placeholder={"ex 160"}
                          name={`pAmount${index}`}
                          value={parameter[`pAmount${index}`]}
                          onChange={(evt) => setParameterValue(evt, index)}
                          required
                          onWheel={(e) => e.target.blur()}
                        />
                      </label>
                      <p className={styles.thirdText}>per mile</p>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className={styles.chargeButtonContainer}>
              <button
                className={styles.extraBtn}
                onClick={
                  (evt) => {
                    evt.preventDefault();
                    addParameter(formValue.parameters.length);
                  }
                }
              >
                Add factor
                <Icon name={IconNames.PLUS} />
              </button>
              <button
                className={styles.removeExtraBtn}
                onClick={
                  (evt) => {
                    evt.preventDefault();
                    removeParameter();
                  }
                }
              >
                Remove factor
                <Icon name={IconNames.MINUS} />
              </button>
            </div>
          </div>
          <SectionTitle title={'Extra Charges'} />
          <div className={styles.distanceContainer}>
            {formValue.extraAmounts.map((amount, index) => {
              return (
                <div className={styles.container} key={index + "eo"}>
                  <div className={styles.shuttleContainer}>
                    <label
                      className={styles.inputContainer}
                      htmlFor={`amount_${index + 1}_amountName`}
                    >
                      <p className={styles.inputName}>Charge name</p>
                      <input
                        id={`amount_${index + 1}_amountName`}
                        className={styles.input}
                        type='text'
                        placeholder=""
                        name={`amountName${index}`}
                        value={amount[`amountName${index}`]}
                        onChange={(evt) => setAmountValue(evt, index)}
                        required
                      />

                    </label>
                    <label
                      className={styles.inputContainerSecond}
                      htmlFor={`amount_${index + 1}_extraAmount`}
                    >
                      <p className={styles.inputNameSecond}>amount</p>
                      <span className={styles.inputValueType}>$</span>
                      <input
                        id={`amount_${index + 1}_extraAmount`}
                        className={styles.inputSecond}
                        type='number'
                        placeholder={"ex 160"}
                        name={`extraAmount${index}`}
                        value={amount[`extraAmount${index}`]}
                        onChange={(evt) => setAmountValue(evt, index)}
                        required
                        onWheel={(e) => e.target.blur()}
                      />
                    </label>
                    <label className={styles.optionsContainer}>
                      <p className={styles.radiobtnContainer}>
                        <input
                          type='radio'
                          value='perJob'
                          name={`type${index}`}
                          checked={amount[`type${index}`] === 'perJob'}
                          onChange={(evt) => setAmountValue(evt, index)}
                        />
                        Per job<br />
                        <input
                          type='radio'
                          value='perTruck'
                          name={`type${index}`}
                          checked={amount[`type${index}`] === 'perTruck'}
                          onChange={(evt) => setAmountValue(evt, index)}
                        />
                        Per truck
                      </p>
                    </label>
                  </div>
                </div>
              )
            })}
            <div className={styles.chargeButtonContainer}>
              <button
                className={styles.extraBtn}
                onClick={
                  (evt) => {
                    evt.preventDefault();
                    addAmount(formValue.extraAmounts.length);
                  }
                }
              >
                Add charge
                <Icon name={IconNames.PLUS} />
              </button>
              <button
                className={styles.removeExtraBtn}
                onClick={
                  (evt) => {
                    evt.preventDefault();
                    removeAmount();
                  }
                }
              >
                Remove charge
                <Icon name={IconNames.MINUS} />
              </button>
            </div>
          </div>
          <SectionTitle title={'Drive Time Round Up'} />

          {formValue.driveTimes.map((driveTime, index) => {
            return (
              <LongDistanceMilesRangeInput
                thirdTitle={"days"}
                values={driveTime}
                changeValues={(evt) => onFormValueChange(`driveTimes.${index}.${evt.target.name}`, 'set', evt.target.value)}
                index={index}
                key={index}
              />
            )
          })}
          <div className={styles.buttonContainer} style={{ left: "100px", position: "relative" }}>
            <button
              className={styles.extraBtn}
              onClick={
                (evt) => {
                  evt.preventDefault();
                  addRange(formValue.driveTimes.length);
                }
              }
            >
              Add range
              <Icon name={IconNames.PLUS} />
            </button>
            <button
              className={styles.removeExtraBtn}
              onClick={
                (evt) => {
                  evt.preventDefault();
                  removeRange();
                }
              }
            >
              Remove range
              <Icon name={IconNames.MINUS} />
            </button>
          </div>

          <SectionTitle title={'Extra trucks'} />
          <label className={styles.extContainer} >
            <div>
              <input
                type='radio'
                defaultValue={formValue.extraMultNumOfTrucks}
                name='extraMultNumOfTrucks'
                checked={formValue.extraMultNumOfTrucks}
                onChange={(evt) => onFormValueChange(`extraMultNumOfTrucks`, 'set', evt.target.checked)}
              />
              <span style={{ color: '#676770', fontSize: '16px' }}>Multiply flat rate by number of trucks</span><br />
              <div style={{ display: "flex" }}>
                <input
                  type='radio'
                  defaultValue={!formValue.extraMultNumOfTrucks}
                  name='extraMultNumOfTrucks'
                  checked={!formValue.extraMultNumOfTrucks}
                  onChange={(evt) => onFormValueChange(`extraMultNumOfTrucks`, 'set', !evt.target.checked)}
                />

                <span className={styles.extInputTitle}>Extra amount per extra truck</span>
                <div style={{ position: "relative" }}>
                  <span className={styles.extInputValueType}>$</span>
                  <input
                    className={styles.extInput}
                    type='number'
                    placeholder="ex 1600"
                    name="extraPerTruckAmount"
                    value={formValue.extraPerTruckAmount}
                    onChange={(evt) => onFormValueChange(`extraPerTruckAmount`, 'set', evt.target.value)}
                    disabled={formValue.extraMultNumOfTrucks}
                    onWheel={(e) => e.target.blur()}
                  />
                </div>
              </div>
            </div>
          </label>


          <BtnSubmit
            isActive={savedStatus === 'idle'}
            action={formSubmit}
            name={'Save'}
          />
        </section>
      </form>
    </FormContainer>
  );
};

// Third paty libraries
import PropTypes from 'prop-types';
//Components
import { DepartmentLabel } from '../department-label/Department-label';
import { DepartmentLabelsec } from '../department-labelsec/Department-labelsec';
import { LongDistanceMilesRangeInput } from '../long-distance-miles-range-input/Long-distance-miles-range-input';
import { LongDistanceSingleMilesRangeInput } from '../long-distance-single-miles-range-input/Long-distance-single-miles-range-input';
import { LongDistanceTypeRadiobuttons } from '../longdistanceType-radiobuttons/LongDistanceType-radiobuttons';
import { DepartmentRadiobuttonsec } from '../department-radiobuttonsec/Department-radiobuttonsec';
import { LongDistanceRadiobuttons } from '../longdistance-radiobuttons/LongDistance-radiobuttons';
import { LongDistanceTitle } from '../longdistance-title/LongDistance-title';
import { SectionTitle } from '../section-title/Section-title';
import { Icon } from '../Icon';
import { LongDistanceLabel } from '../longdistance-label/LongDistance-label';
import { LongDistanceLabelsec } from '../longdistance-labelsec/LongDistance-labelsec';
import { LongDistanceLabelthrd } from '../longdistance-labelthrd/LongDistance-labelthrd';
import { BtnSubmit } from '../btn-submit/Btn-submit';
import { useSelector } from 'react-redux';
// Utils
import { IconNames } from '../../util/const';
// Styles
import styles from './LongDistance.module.css';
import { getLongDistanceSavingStatus } from '../../clientStore/longdistanceSlice/longdistance-slice';


export const LongDistance = (props) => {
  const {
    changeDistanceValue,
    distance,
    setFormValue,
    longdistance,
    longDistances,
    userId,
    companyId,
    onChangeSameAsSelect,
    renderLongDistancesOptions,
    clearLastTarget,
    formSubmit
  } = props;

  const longDistanceSavingStatus=useSelector(getLongDistanceSavingStatus);

  const changeRoundToFull = (value) => {
    switch (value) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        throw new Error('invalid data');
    }
  };

  const setAmountValue = (evt, index) => {
    changeDistanceValue(`fuelExpenses.extraAmounts.${index}.${evt.target.name}`, 'set', evt.target.value);
  }

  const setRDAmountValue = (evt, index) => {
    changeDistanceValue(`extraAmountsRD.${index}.${evt.target.name}`, 'set', evt.target.value);
  }

  const addAmount = (index) => {
    const newAmount = {
      [`amountName${index}`]: '',
      [`extraAmount${index}`]: '',
      [`type${index}`]: 'perJob'
    }
    changeDistanceValue(`fuelExpenses.extraAmounts`, 'push', newAmount);
  }

  const removeAmount = () => {
    changeDistanceValue(`fuelExpenses.extraAmounts.${distance.fuelExpenses.extraAmounts.length - 1}`, 'del');
  }

  const addRDAmount = (index) => {
    const newAmount = {
      [`amountNameRD${index}`]: '',
      [`extraAmountRD${index}`]: '',
      [`typeRD${index}`]: 'perJob'
    }
    changeDistanceValue(`extraAmountsRD`, 'push', newAmount);
  }

  const removeRDAmount = () => {
    changeDistanceValue(`extraAmountsRD.${distance.extraAmountsRD.length - 1}`, 'del');
  }

  const addRange = (index) => {
    const newRange = {
      [`rangeStart${index}`]: '',
      [`rangeEnd${index}`]: '',
      [`hours${index}`]: ''
    }
    changeDistanceValue(`driveTimes`, 'push', newRange);
  }

  const removeRange = () => {
    changeDistanceValue(`driveTimes.${distance.driveTimes.length - 1}`, 'del');
  }

  const addRangeRD = (index) => {
    const newRange = {
      [`rangeStartRD${index}`]: '',
      [`rangeEndRD${index}`]: '',
      [`milesRD${index}`]: ''
    }
    changeDistanceValue(`driveTimesRD`, 'push', newRange);
  }

  const removeRangeRD = () => {
    changeDistanceValue(`driveTimesRD.${distance.driveTimes.length - 1}`, 'del');
  }

  const onChangeDirectionType = (evt) => {
    const typeValue = evt.target.value === 'true' ? 'expences' : 'roomDistance';
    changeDistanceValue(`directionType`, 'set', typeValue);
  };

  const onChangeCalcMethod = (evt) => {
    const typeValue = evt.target.value === 'true' ? 'discount' : 'deduct';
    changeDistanceValue('calcMethod', 'set', typeValue);
  };

  return (
    <section>
      <div className={styles.directionName}>
        <DepartmentLabelsec
          inputId={'directionName'}
          name={'directionName'}
          title={'Direction name'}
          placeholder={'For example: LA-SF'}
          value={distance.directionName}
          changeValue={(evt) => changeDistanceValue(`directionName`, 'set', evt.target.value)}
        />
        <div style={{ position: "relative", marginLeft: "150px" }}>
          <label htmlFor='sameAs' className={styles.fieldName}>Same as</label>
          <select
            id='sameAs'
            className={styles.departmentField}
            onFocus={clearLastTarget}
            onChange={onChangeSameAsSelect}
          >
            <option value=''>None</option>
            {renderLongDistancesOptions()}
          </select>
        </div>
      </div>
      <section className={styles.allPage}>
        <div className={styles.titleContainer}>
          <LongDistanceTitle title={'Long Distance'} />
          <LongDistanceTypeRadiobuttons
            name={'directionType'}
            isChecked={distance.directionType === 'expences'}
            onChangeValue={onChangeDirectionType}
            firstValue={'Based on expences'}
            secondValue={'Based on room and distance'}
          />
        </div>

        <div className={styles.calcMethContainer}>
          <LongDistanceRadiobuttons
            title={'Calculation method'}
            name={'calcMethod'}
            isChecked={distance.calcMethod === 'discount'}
            onChangeValue={onChangeCalcMethod}
            firstValue={'cash discount % from total'}
            secondValue={'deduct $ for cash payment'}
          />

          <div className={styles.cashPaymentContainer}>
            {(distance.calcMethod === 'discount') ?
              <LongDistanceLabelsec
                title="Discount for CASH PAYMENT "
                placeholder="ex: 5"
                name='cashPaymentDiscount'
                value={distance.cashPaymentDiscount}
                changeValue={(evt) => changeDistanceValue(`${evt.target.name}`, 'set', evt.target.value)}
                valueType={'%'}
                inputId='cashPaymentDiscount'
                inputType='number'
              /> :
              <LongDistanceLabelsec
                title="Deduct $ for CASH PAYMENT"
                placeholder="ex: 200"
                name='cashPaymentDeduct'
                value={distance.cashPaymentDeduct}
                changeValue={(evt) => changeDistanceValue(`${evt.target.name}`, 'set', evt.target.value)}
                valueType={'$'}
                inputId='packingRD'
                inputType='number'
              />
            }
          </div>
        </div>

        {distance.directionType === "expences" && <>
          <SectionTitle title={'Salary Expenses'} />
          <div className={styles.distanceContainer}>
            <DepartmentLabel
              inputId={'foreman'}
              name={'foreman'}
              title={'Foreman'}
              placeholder={'ex: 23'}
              value={distance.salaryExpenses.foreman}
              changeValue={(evt) => changeDistanceValue(`salaryExpenses.foreman`, 'set', evt.target.value)}
              valueType={'$/hour'}
              isLong={true}
              inputType='number'
            />
          </div>
          <div className={styles.distanceContainer}>
            <DepartmentLabel
              inputId={'helperAtLoading'}
              name={'helperAtLoading'}
              title={'Helper at loading'}
              placeholder={'ex: 23'}
              value={distance.salaryExpenses.helperAtLoading}
              changeValue={(evt) => changeDistanceValue(`salaryExpenses.helperAtLoading`, 'set', evt.target.value)}
              valueType={'$/hour'}
              isLong={true}
              inputType='number'
            />
          </div>
          <div className={styles.distanceContainer}>
            <DepartmentLabel
              inputId={'helperAtDriving'}
              name={'helperAtDriving'}
              title={'Helper at driving'}
              placeholder={'ex: 23'}
              value={distance.salaryExpenses.helperAtDriving}
              changeValue={(evt) => changeDistanceValue(`salaryExpenses.helperAtDriving`, 'set', evt.target.value)}
              valueType={'$/hour'}
              isLong={true}
              inputType='number'
            />
          </div>
          <div className={styles.distanceContainer}>
            <DepartmentLabel
              inputId={'helperAtUnloading'}
              name={'helperAtUnloading'}
              title={'Helper at unloading'}
              placeholder={'ex: 23'}
              value={distance.salaryExpenses.helperAtUnloading}
              changeValue={(evt) => changeDistanceValue(`salaryExpenses.helperAtUnloading`, 'set', evt.target.value)}
              valueType={'$/hour'}
              isLong={true}
              inputType='number'
            />
          </div>
          <SectionTitle title={'Fuel Expenses'} />
          <div className={styles.distanceContainer}>
            <DepartmentLabel
              inputId={'fuelPerGallon'}
              name={'fuelPerGallon'}
              title={'Fuel $ per gallon'}
              placeholder={'ex: 4'}
              value={distance.fuelExpenses.fuelPerGallon}
              changeValue={(evt) => changeDistanceValue(`fuelExpenses.fuelPerGallon`, 'set', evt.target.value)}
              valueType={'$'}
              inputType='number'
            />
          </div>
          <div className={styles.distanceContainer}>
            <DepartmentLabel
              inputId={'milesPerGallon'}
              name={'milesPerGallon'}
              title={'Miles per gallon'}
              placeholder={'ex: 6'}
              value={distance.fuelExpenses.milesPerGallon}
              changeValue={(evt) => changeDistanceValue(`fuelExpenses.milesPerGallon`, 'set', evt.target.value)}
              /*  valueType={'$'} */
              inputType='number'
            />
          </div>
          <div className={styles.distanceContainer}>
            <DepartmentLabel
              inputId={'wrappingMaterials'}
              name={'wrappingMaterials'}
              title={'Wrapping materials'}
              placeholder={'ex: 25'}
              value={distance.fuelExpenses.wrappingMaterials}
              changeValue={(evt) => changeDistanceValue(`fuelExpenses.wrappingMaterials`, 'set', evt.target.value)}
              valueType={'$'}
              inputType='number'
            />
          </div>
          <div className={styles.distanceContainer}>
            <DepartmentLabel
              inputId={'oosLogBook'}
              name={'oosLogBook'}
              title={'OOS logbook'}
              placeholder={'ex: 200'}
              value={distance.fuelExpenses.oosLogBook}
              changeValue={(evt) => changeDistanceValue(`fuelExpenses.oosLogBook`, 'set', evt.target.value)}
              valueType={'$'}
              inputType='number'
            />
          </div>
          <div className={styles.distanceContainer}>
            <DepartmentLabel
              inputId={'hotel'}
              name={'hotel'}
              title={'Hotel'}
              placeholder={'ex: 200'}
              value={distance.fuelExpenses.hotel}
              changeValue={(evt) => changeDistanceValue(`fuelExpenses.hotel`, 'set', evt.target.value)}
              valueType={'$'}
              inputType='number'
            />
          </div>
          <div className={styles.distanceContainer}>

            <SectionTitle title={'Extra Charges'} />
            {distance.fuelExpenses.extraAmounts.map((amount, index) => {
              return (
                <div className={styles.container} key={index + "eo"}>
                  <div className={styles.shuttleContainer}>
                    <label
                      className={styles.inputContainer}
                      htmlFor={`amount_${index + 1}_amountName`}
                    >
                      <p className={styles.inputName}>Charge name</p>
                      <input
                        id={`amount_${index + 1}_amountName`}
                        className={styles.input}
                        type='text'
                        placeholder=""
                        name={`amountName${index}`}
                        value={amount[`amountName${index}`]}
                        onChange={(evt) => setAmountValue(evt, index)}
                        required
                      />

                    </label>
                    <label
                      className={styles.inputContainerSecond}
                      htmlFor={`amount_${index + 1}_extraAmount`}
                    >
                      <p className={styles.inputNameSecond}>amount</p>
                      <span className={styles.inputValueType}>$</span>
                      <input
                        id={`amount_${index + 1}_extraAmount`}
                        className={styles.inputSecond}
                        type='number'
                        placeholder={"ex 160"}
                        name={`extraAmount${index}`}
                        value={amount[`extraAmount${index}`]}
                        onChange={(evt) => setAmountValue(evt, index)}
                        required
                        onWheel={(e) => e.target.blur()}
                      />
                    </label>
                    <label className={styles.optionsContainer}>
                      <p className={styles.radiobtnContainer}>
                        <input
                          style={{ marginLeft: "10px" }}
                          type='radio'
                          value='perJob'
                          name={`type${index}`}
                          checked={amount[`type${index}`] === 'perJob'}
                          onChange={(evt) => setAmountValue(evt, index)}
                        />
                        Per job<br />
                        <input
                          type='radio'
                          value='perTruck'
                          name={`type${index}`}
                          checked={amount[`type${index}`] === 'perTruck'}
                          onChange={(evt) => setAmountValue(evt, index)}
                        />
                        Per truck
                      </p>
                    </label>

                  </div>
                </div>

              )
            })}

            <div className={styles.chargeButtonContainer}>
              <button
                className={styles.extraBtn}
                onClick={
                  (evt) => {
                    evt.preventDefault();
                    addAmount(distance.fuelExpenses.extraAmounts.length);
                  }
                }
              >
                Add charge
                <Icon name={IconNames.PLUS} />
              </button>
              <button
                className={styles.removeExtraBtn}
                onClick={
                  (evt) => {
                    evt.preventDefault();
                    removeAmount();
                  }
                }
              >
                Remove charge
                <Icon name={IconNames.MINUS} />
              </button>
            </div>
          </div>
          <div className={styles.distanceContainer}>
            <SectionTitle title={'Drive Time Round Up'} />

            {distance.driveTimes.map((driveTime, index) => {
              return (
                <LongDistanceMilesRangeInput
                  thirdTitle={"hours"}
                  values={driveTime}
                  changeValues={(evt) => changeDistanceValue(`driveTimes.${index}.${evt.target.name}`, 'set', evt.target.value)}
                  index={index}
                  key={index}
                />
              )
            })}
            <div className={styles.buttonContainer} style={{ left: "50px", position: "relative" }}>
              <button
                className={styles.extraBtn}
                onClick={
                  (evt) => {
                    evt.preventDefault();
                    addRange(distance.driveTimes.length);
                  }
                }
              >
                Add range
                <Icon name={IconNames.PLUS} />
              </button>
              <button
                className={styles.removeExtraBtn}
                onClick={
                  (evt) => {
                    evt.preventDefault();
                    removeRange();
                  }
                }
              >
                Remove range
                <Icon name={IconNames.MINUS} />
              </button>
            </div>
          </div>
          <SectionTitle title={'Trucks Round Up'} />
          <div className={styles.distanceContainer}>
            <DepartmentRadiobuttonsec
              title='Round up all truckloads after the first one to full'
              name='roundToFull'
              isChecked={distance.roundToFull}
              onChangeValue={(evt) => changeDistanceValue(`roundToFull`, 'set', changeRoundToFull(evt.target.value))}
              firstValue='Yes'
              secondValue='No'
            />
          </div>
        </>
        }

        {distance.directionType === "roomDistance" &&
          <>
            <SectionTitle title={'Charges'} />
            <div className={styles.dirTypeFirstContainer}>
              <div className={styles.distanceContainer}>
                <LongDistanceSingleMilesRangeInput
                  values={
                    {
                      rangeStartRD: distance.rangeStartRD,
                      rangeEndRD: distance.rangeEndRD,
                      amountRD: distance.amountRD,
                    }
                  }
                  changeValues={(evt) => changeDistanceValue(`${evt.target.name}`, 'set', evt.target.value)}
                />
              </div>
              <div className={styles.distanceContainer}>
                <LongDistanceLabel
                  title="Flat rate per extra room/office/garage"
                  placeholder="ex 200"
                  valueType={'$'}
                  name='flatRateRD'
                  value={distance.flatRateRD}
                  changeValue={(evt) => changeDistanceValue(`${evt.target.name}`, 'set', evt.target.value)}
                  inputId='flatRateRD'
                  inputType='number'
                />
              </div>
              <div className={styles.distanceContainer}>
                <LongDistanceLabel
                  title="Packing for 1 bedroom apartment (living room, kitchen, one bedroom)"
                  placeholder="ex 600"
                  valueType={'$'}
                  name='packingRD'
                  value={distance.packingRD}
                  changeValue={(evt) => changeDistanceValue(`${evt.target.name}`, 'set', evt.target.value)}
                  inputId='packingRD'
                  inputType='number'
                />
              </div>
              <div className={styles.distanceContainer}>
                <LongDistanceLabel
                  title="Packing per extra room/office/garage"
                  placeholder="ex 200"
                  valueType={'$'}
                  name={'extraPackingRD'}
                  value={distance.extraPackingRD}
                  changeValue={(evt) => changeDistanceValue(`${evt.target.name}`, 'set', evt.target.value)}
                  inputId='extraPackingRD'
                  inputType='number'
                />
              </div>
              <div className={styles.distanceContainer}>
                <LongDistanceLabel
                  title="Extra truck + extra driver"
                  placeholder="ex 1600"
                  valueType={'$'}
                  name='extraTruckDriverRD'
                  value={distance.extraTruckDriverRD}
                  changeValue={(evt) => changeDistanceValue(`${evt.target.name}`, 'set', evt.target.value)}
                  inputId='extraTruckDriverRD'
                  inputType='number'
                />
              </div>
            </div>
            <SectionTitle title={'Extra Charges'} />
            <div className={styles.distanceContainer}>
              {distance.extraAmountsRD.map((amount, index) => {
                return (
                  <div className={styles.container} key={index + "eo"}>
                    <div className={styles.shuttleContainer}>
                      <label
                        className={styles.inputContainer}
                        htmlFor={`amount${index + 1}_amountNameRD`}
                      >
                        <p className={styles.inputName}>Charge name</p>
                        <input
                          id={`amount${index + 1}_amountNameRD`}
                          className={styles.input}
                          type='text'
                          placeholder=""
                          name={`amountNameRD${index}`}
                          value={amount[`amountNameRD${index}`]}
                          onChange={(evt) => setRDAmountValue(evt, index)}
                          required
                        />

                      </label>
                      <label
                        className={styles.inputContainerSecond}
                        htmlFor={`amount_${index + 1}_extraAmountRD`}
                      >
                        <p className={styles.inputNameSecond}>amount</p>
                        <span className={styles.inputValueType}>$</span>
                        <input
                          id={`amount_${index + 1}_extraAmountRD`}
                          className={styles.inputSecond}
                          type='number'
                          placeholder={"ex 160"}
                          name={`extraAmountRD${index}`}
                          value={amount[`extraAmountRD${index}`]}
                          onChange={(evt) => setRDAmountValue(evt, index)}
                          onWheel={(e) => e.target.blur()}
                          required
                        />
                      </label>
                      <label className={styles.optionsContainer}>
                        <p className={styles.radiobtnContainer}>
                          <input
                            type='radio'
                            value='perJob'
                            name={`typeRD${index}`}
                            checked={amount[`typeRD${index}`] === 'perJob'}
                            onChange={(evt) => setRDAmountValue(evt, index)}
                          />
                          Per job<br />
                          <input
                            type='radio'
                            value='perTruck'
                            name={`typeRD${index}`}
                            checked={amount[`typeRD${index}`] === 'perTruck'}
                            onChange={(evt) => setRDAmountValue(evt, index)}
                          />
                          Per truck
                        </p>
                      </label>
                    </div>
                  </div>
                )
              })}

              <div className={styles.chargeButtonContainer}>
                <button
                  className={styles.extraBtn}
                  onClick={
                    (evt) => {
                      evt.preventDefault();
                      addRDAmount(distance.extraAmountsRD.length);
                    }
                  }
                >
                  Add charge
                  <Icon name={IconNames.PLUS} />
                </button>
                <button
                  className={styles.removeExtraBtn}
                  onClick={
                    (evt) => {
                      evt.preventDefault();
                      removeRDAmount();
                    }
                  }
                >
                  Remove charge
                  <Icon name={IconNames.MINUS} />
                </button>
              </div>
            </div>
            <SectionTitle title={'Arrival Time'} />
            <div className={styles.distanceContainer}>
              <LongDistanceLabelthrd
                title="Days"
                placeholder="ex 3"
                name={'arrivalTimeDaysRD'}
                value={distance.arrivalTimeDaysRD}
                changeValue={(evt) => changeDistanceValue(`${evt.target.name}`, 'set', evt.target.value)}
                inputId='arrivalTimeDaysRD'
                inputType='number'
              />
            </div>
         
          </>
        }
        
         <BtnSubmit
          isActive={longDistanceSavingStatus==='idle'}
          action={formSubmit}
          name={'Save'}
        />
      </section>
    </section>
  );
};

LongDistance.propTypes = {
  distance: PropTypes.object.isRequired,
  changeDistanceValue: PropTypes.func.isRequired,
}

// Third party libraries
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { nanoid } from 'nanoid';
import * as immutable from 'object-path-immutable';
// Components
import { BtnSubmit } from '../btn-submit/Btn-submit';
import { ExtraOptions } from '../extra-options/Extra-options';
import { FormContainer } from '../form-container/Form-container';

// Local State Functions
import { getUserId, getCompanyId } from '../../clientStore/authSlice/auth-slice';
import {
  getCompanySavingStatus,
  setCompanySavingStatus,
  setCompanyLoadingStatus,
  setCompanyRemovingStatus
} from '../../clientStore/companySlice/company-slice';
import {
  saveCompany,
  updateCompany,
} from '../../clientStore/companySlice/company-async-thunk';
import {
  getCompanies,
  setCompaniesLoadingStatus,
} from '../../clientStore/companiesSlice/companies-slice';
// DataModel
import { companyDataModel } from '../../models/company';
// Utils
import { fetchedData } from '../../util/utils';
//Styles
import styles from './CompanyForm.module.css';

export const CompanyForm = ({ companyData, formType = 'new' }) => {

  const [lastTarget, setLastTarget] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const userId = useSelector(getUserId);
  const companyId = useSelector(getCompanyId);
  const savedStatus = useSelector(getCompanySavingStatus);
  const companies = useSelector(getCompanies);
  const company = companyData ? companyData : companyDataModel;


  const [formValue, setFormValue] = useState({
    ...company,
    authorId: userId,
    version: company.version + 1,
    companyId,
  });

  const renderCompaniesOptions = (companies) => {
    if (companies.length > 0) {
      return (
        companies.map((company) => {
          return (
            <option value={company._id} key={nanoid(companies.length)}>
              {company.companyName}
            </option>
          );
        })
      );
    }
    return ('');
  };

  const onChangeSameAsSelect = async (evt) => {
    const copiedCompany = await fetchedData('company/getCompany', 'POST', { id: evt.target.value });
    if (copiedCompany.status === 200) {
      setFormValue({
        ...copiedCompany.data,
        _id: company._id ? company._id : undefined,
        authorId: userId,
        version: company.version + 1,
        companyId,
        companyName: company.companyName,
      });
    }
  }

  const formSubmit = (evt) => {
    evt.preventDefault();

    switch (formType) {
      case 'new':
        dispatch(saveCompany(formValue));
        break;
      case 'edit':
        dispatch(updateCompany(formValue, companyId));
        break;
      default:
        throw new Error('Invalid form type');
    }

    setFormValue({
      ...companyDataModel,
      authorId: userId,
      companyId,
    });
  };

  useEffect(() => {
    if (savedStatus === 'successed') {
      dispatch(setCompanySavingStatus({ status: 'idle' }));
      dispatch(setCompanyLoadingStatus({ status: 'idle' }));
      dispatch(setCompaniesLoadingStatus({ status: 'idle' }));
      dispatch(setCompanyRemovingStatus({ status: 'idle' }));
      history.replace('/companies');
    }
  }, [savedStatus, history, dispatch]);

  const onFormValueChange = (path, method, value) => {
    setFormValue(immutable[method](formValue, path, value));
  };

  const clearLastTarget = () => {
    if (lastTarget) {
      setLastTarget(undefined);
    }
  }


  return (
    <FormContainer>
      <form className={styles.form}>
        <h2 className='visually-hidden'>Form for add new company</h2>
        <fieldset className={styles.formHeader}>
          <legend className="visually-hidden">Common settings for company</legend>
          <div style={{ width: "300px" }}>
            <div className={styles.fieldContainer}>
              <label htmlFor='companyName' className={styles.fieldName}>Company name</label>
              <input
                id='companyName'
                className={styles.companyField}
                type='text'
                placeholder='Company name'
                name='companyName'
                value={formValue.companyName}
                onChange={(evt) => onFormValueChange(evt.target.name, 'set', evt.target.value)}
                onFocus={clearLastTarget}
                required
              />
            </div>

            <div>
              <label>
                <input type='checkbox' checked={formValue.disabled} name="disabled" onChange={(evt) => onFormValueChange(evt.target.name, 'set', evt.target.checked)} /> Disabled
              </label>
            </div>
          </div>
        </fieldset>

        <BtnSubmit
          isActive={savedStatus === 'idle'}
          action={formSubmit}
          name={'Save'}
        />
      </form>
    </FormContainer>
  );
};

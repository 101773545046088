// Third party libraries
import { useState, useEffect, useRef, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SocketContext } from '../../context/socket';

// Components
import Select from 'react-select'
import { Editor, bold, italic, underline, strikethrough, } from "react-draft-wysiwyg";
import { EditorState, Modifier, convertToRaw, convertFromRaw, ContentState, convertFromHTML, SelectionState, RichUtils } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import { getUserId, getCompanyId } from '../../clientStore/authSlice/auth-slice';
import { handleNewLine, insertNewUnstyledBlock } from 'draftjs-utils';
import htmlToDraft from 'html-to-draftjs';
import { FontFamiliesOptions } from '../../util/const';
// Styles
import styles from './TemplateColumn.module.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { getTagsValues, replaceOptionsTagsByValues, replaceTagsByValues } from '../../util/utils';
import { TemplatePreviewPopup } from '../template-preview-popup/Template-preview-popup';
import { BtnSubmit } from '../btn-submit/Btn-submit';
import { setTotal_cubesG, setTotalFragileBoxes, setTotalMediumBoxes, setTotalSmallBoxes } from '../../clientStore/calculatedParamsSlice/calculated-params-slice';
import { getTemplateSavingStatus } from '../../clientStore/templateSlice/template-slice';




export const TemplateColumn = ({ templateForm, saveBtnEvent, onFormValueChange, updateTempState, setUpdateTempState, company, setTagsResultsTexts, tagsResultsTexts, department, furniture, roomsAreas }) => {
  const dispatch = useDispatch();
  const [Total_cubes, setTotal_cubes] = useState(0);
  const [Total_small_boxes, setTotal_small_boxes] = useState(0);
  const [Total_medium_boxes, setTotal_medium_boxes] = useState(0)
  const [Total_fragile_boxes, setTotal_fragile_boxes] = useState(0)
  const [Total_box_cubes, setTotal_box_cubes] = useState(0)
  const [Stop_1_Truckload, setStop_1_Truckload] = useState(0)
  const [Stop_CN_Truckload, setStop_CN_Truckload] = useState(0)
  const [Charge_per_mile_amount, setCharge_per_mile_amount] = useState(0)
  const [Movers_to_load_One_Day_hours, setMovers_to_load_One_Day_hours] = useState(0)
  const [Loading_hours_One_Day, setLoading_hours_One_Day] = useState(0)
  const [Adjusted_loading_time_One_Day, setAdjusted_loading_time_One_Day] = useState(0)
  const [Drivetime_One_Day, setDrivetime_One_Day] = useState(0)
  const [Movers_to_unload_One_Day_hours, setMovers_to_unload_One_Day_hours] = useState(0)
  const [Unloading_hours_One_Day, setUnloading_hours_One_Day] = useState(0)
  const [Total_Hours_One_Day, setTotal_Hours_One_Day] = useState(0)
  const [Truckloads, setTruckloads] = useState(0)
  const [Total_Destinationfee_One_Day, setTotal_Destinationfee_One_Day] = useState(0)
  const [Rate_cash_Movers_To_Load_One_Day, setRate_cash_Movers_To_Load_One_Day] = useState(0)
  const [Rate_card_Movers_To_Load_One_Day, setRate_card_Movers_To_Load_One_Day] = useState(0)
  const [Truck_fee_One_Day, setTruck_fee_One_Day] = useState(0)
  const [Movers_One_Day, setMovers_One_Day] = useState(0)
  const [Rate_cash_One_Day, setRate_cash_One_Day] = useState(0)
  const [Rate_card_One_Day, setRate_card_One_Day] = useState(0)
  const [Rate_cash_Movers_To_Unload_One_Day, setRate_cash_Movers_To_Unload_One_Day] = useState(0)
  const [Rate_card_Movers_To_Unload_One_Day, setRate_card_Movers_To_Unload_One_Day] = useState(0)
  const [Trucks_One_Day, setTrucks_One_Day] = useState(0)
  const [TWO_Trips_Or_OK, setTWO_Trips_Or_OK] = useState('')
  const [BIGGEST_or_OK, setBIGGEST_or_OK] = useState('')
  const [Double_drive, setDouble_drive] = useState('')
  const [Lower_Number_of_Hours_One_Day, setLower_Number_of_Hours_One_Day] = useState(0)
  const [Higher_Number_of_Hours_One_Day, setHigher_Number_of_Hours_One_Day] = useState(0)
  const [Heavy_fee, setHeavy_fee] = useState(0)
  const [Packing_kit_Show, setPacking_kit_Show] = useState(0)
  const [Packing_kit_total_amount_with_taxes, setPacking_kit_total_amount_with_taxes] = useState(0)
  const [Total_Card_One_day, setTotal_Card_One_day] = useState(0)
  const [Total_Cash_One_day, setTotal_Cash_One_day] = useState(0)
  const [Combined_mileage, setCombined_mileage] = useState(0)
  const [Flat_rate_One_Day, setFlat_rate_One_Day] = useState(0)
  const [Flat_rate_One_Day_cash, setFlat_rate_One_Day_cash] = useState(0)
  const [Packing_Charge, setPacking_Charge] = useState(0)
  const [UnPacking_Charge, setUnPacking_Charge] = useState(0)



  const [Trucks_Unpacking_Separate_Day, setTrucks_Unpacking_Separate_Day] = useState(0)
  const [Movers_Unpacking_Separate_Day, setMovers_Unpacking_Separate_Day] = useState(0)
  const [Unpacking_Separate_Day_Lower_hours, setUnpacking_Separate_Day_Lower_hours] = useState(0)
  const [Unpacking_Separate_Day_Higher_hours, setUnpacking_Separate_Day_Higher_hours] = useState(0)
  const [Total_Card_Unpacking_Separate_Day, setTotal_Card_Unpacking_Separate_Day] = useState(0)
  const [Total_Cash_Unpacking_Separate_Day, setTotal_Cash_Unpacking_Separate_Day] = useState(0)
  const [Rate_cash_Unpacking_Separate_Day, setRate_cash_Unpacking_Separate_Day] = useState(0)
  const [Rate_card_Unpacking_Separate_Day, setRate_card_Unpacking_Separate_Day] = useState(0)
  const [Truck_fee_Unpacking_Separate_Day, setTruck_fee_Unpacking_Separate_Day] = useState(0)
  const [Flat_rate_Unpacking_Separate_Day, setFlat_rate_Unpacking_Separate_Day] = useState(0)
  const [Flat_rate_Unpacking_Separate_Day_cash, setFlat_rate_Unpacking_Separate_Day_cash] = useState(0)

  const [Time_to_pack, setTime_to_pack] = useState(0);

  const didMount = useRef(false);
  const currentUserId = useSelector(getUserId);
  const socket = useContext(SocketContext);
  const [stateTagValues, setStateTagValues] = useState({});
  const { tags, templateEditorState } = templateForm;
  const calculationForm = templateForm.templateCalcObj;



  const [TWO_Trips_Or_OK_Day2, setTWO_Trips_Or_OK_Day2] = useState(0);
  const [Loading_hours_150miles, setLoading_hours_150miles] = useState(0);
  const [Total_Hours_150miles_Day1, setTotal_Hours_150miles_Day1] = useState(0);
  const [Unloading_hours_150miles, setUnloading_hours_150miles] = useState(0);
  const [Total_Hours_150miles_Day2, setTotal_Hours_150miles_Day2] = useState(0);
  const [Movers_150miles_Day1, setMovers_150miles_Day1] = useState(0);
  const [Movers_150miles_Day2, setMovers_150miles_Day2] = useState(0);
  const [Total_Destinationfee_150miles, setTotal_Destinationfee_150miles] = useState(0);
  const [Double_drive_150miles_Day1, setDouble_drive_150miles_Day1] = useState(0);
  const [Lower_Number_of_Hours_150miles_Day1, setLower_Number_of_Hours_150miles_Day1] = useState(0);
  const [Higher_Number_of_Hours_150miles_Day2, setHigher_Number_of_Hours_150miles_Day2] = useState(0);
  const [Higher_Number_of_Hours_150miles_Day1, setHigher_Number_of_Hours_150miles_Day1] = useState(0);
  const [Trucks_150miles_Day2, setTrucks_150miles_Day2] = useState(0);
  const [Double_drive_150miles_Day2, setDouble_drive_150miles_Day2] = useState(0);
  const [Lower_Number_of_Hours_150miles_Day2, setLower_Number_of_Hours_150miles_Day2] = useState(0);
  const [Total_Card_150miles_2Days, setTotal_Card_150miles_2Days] = useState(0);
  const [Total_Cash_150miles_Day2, setTotal_Cash_150miles_Day2] = useState(0);
  const [Total_Cash_150miles_2Days, setTotal_Cash_150miles_2Days] = useState(0);
  const [Driving_time_with_DDT_150miles_Day1, setDriving_time_with_DDT_150miles_Day1] = useState(0);
  const [Driving_time_with_DDT_150miles_Day2, setDriving_time_with_DDT_150miles_Day2] = useState(0);
  const [Rate_cash_150miles_Day1, setRate_cash_150miles_Day1] = useState(0);
  const [Total_Cash_150miles_Day1, setTotal_Cash_150miles_Day1] = useState(0);
  const [Total_Card_150miles_Day1, setTotal_Card_150miles_Day1] = useState(0);
  const [Total_Card_150miles_Day2, setTotal_Card_150miles_Day2] = useState(0);
  const [Rate_card_150miles_Day1, setRate_card_150miles_Day1] = useState(0);
  const [Trucks_150miles_Day1, setTrucks_150miles_Day1] = useState(0);
  const [Destination_fee_150miles, setDestination_fee_150miles] = useState(0);
  const [Truck_fee_150miles_Day1, setTruck_fee_150miles_Day1] = useState(0);
  const [Rate_cash_150miles_Day2, setRate_cash_150miles_Day2] = useState(0);
  const [Rate_card_150miles_Day2, setRate_card_150miles_Day2] = useState(0);
  const [Truck_fee_150miles_Day2, setTruck_fee_150miles_Day2] = useState(0);

  const [Loading_hours_3days_150_Day1, setLoading_hours_3days_150_Day1] = useState(0)
  const [Driving_Time_3days_150_Day1, setDriving_Time_3days_150_Day1] = useState(0)
  const [Unloading_hours_3days_150_Day1, setUnloading_hours_3days_150_Day1] = useState(0)
  const [Packing_Time_3days_150_Day1, setPacking_Time_3days_150_Day1] = useState(0)
  const [Wrapping_time_3days_150_Day1, setWrapping_time_3days_150_Day1] = useState(0)
  const [Total_Hours_3days_150_Day1, setTotal_Hours_3days_150_Day1] = useState(0)
  const [Total_Cash_3days_150_Day1, setTotal_Cash_3days_150_Day1] = useState(0)
  const [Total_Card_3days_150_Day1, setTotal_Card_3days_150_Day1] = useState(0)
  const [Loading_hours_3days_150_Day2, setLoading_hours_3days_150_Day2] = useState(0)
  const [Driving_time_with_DDT_3days_150_Day2, setDriving_time_with_DDT_3days_150_Day2] = useState(0)
  const [Total_Hours_3days_150_Day2, setTotal_Hours_3days_150_Day2] = useState(0)
  const [Total_Cash_3days_150_Day2, setTotal_Cash_3days_150_Day2] = useState(0)
  const [Total_Card_3days_150_Day2, setTotal_Card_3days_150_Day2] = useState(0)
  const [Unloading_hours_3days_150_Day3, setUnloading_hours_3days_150_Day3] = useState(0)
  const [Driving_time_with_DDT_3days_150_Day3, setDriving_time_with_DDT_3days_150_Day3] = useState(0)
  const [Total_Hours_3days_150_Day3, setTotal_Hours_3days_150_Day3] = useState(0)
  const [Total_Cash_3days_150_Day3, setTotal_Cash_3days_150_Day3] = useState(0)
  const [Total_Card_3days_150_Day3, setTotal_Card_3days_150_Day3] = useState(0)
  const [Movers_To_Pack_3days_150_Day1, setMovers_To_Pack_3days_150_Day1] = useState(0)
  const [Total_Destinationfee_3days_150, setTotal_Destinationfee_3days_150] = useState(0)
  const [Rate_cash_3days_150_Day1, setRate_cash_3days_150_Day1] = useState(0)
  const [Rate_card_3days_150_Day1, setRate_card_3days_150_Day1] = useState(0)
  const [Truck_fee_3days_150_Day1, setTruck_fee_3days_150_Day1] = useState(0)
  const [Trucks_To_Pack_3days_150_Day1, setTrucks_To_Pack_3days_150_Day1] = useState(0)
  const [cubicf_3days_150_Day1, setCubicf_3days_150_Day1] = useState(0)
  const [Lower_Number_of_Hours_3days_150_Day1, setLower_Number_of_Hours_3days_150_Day1] = useState(0)
  const [Higher_Number_of_Hours_3days_150_Day1, setHigher_Number_of_Hours_3days_150_Day1] = useState(0)

  const [Round_trip_miles, setRound_trip_miles] = useState(0);
  const [Movers_3days_150_Day2, setMovers_3days_150_Day2] = useState(0);
  const [Total_Destinationfee_3days_150_Day2, setTotal_Destinationfee_3days_150_Day2] = useState(0);
  const [Rate_card_3days_150_Day2, setRate_card_3days_150_Day2] = useState(0);
  const [Rate_cash_3days_150_Day2, setRate_cash_3days_150_Day2] = useState(0);
  const [Truck_fee_3days_150_Day2, setTruck_fee_3days_150_Day2] = useState(0);
  const [Trucks_3days_150_Day2, setTrucks_3days_150_Day2] = useState(0);
  const [Double_drive_3days_150_Day2, setDouble_drive_3days_150_Day2] = useState(0);
  const [Lower_Number_of_Hours_3days_150_Day2, setLower_Number_of_Hours_3days_150_Day2] = useState(0);
  const [Higher_Number_of_Hours_3days_150_Day2, setHigher_Number_of_Hours_3days_150_Day2] = useState(0);
  const [Movers_3days_150_Day3, setMovers_3days_150_Day3] = useState(0);
  const [Rate_cash_3days_150_Day3, setRate_cash_3days_150_Day3] = useState(0);
  const [Rate_card_3days_150_Day3, setRate_card_3days_150_Day3] = useState(0);
  const [Truck_fee_3days_150_Day3, setTruck_fee_3days_150_Day3] = useState(0);
  const [Trucks_3days_150_Day3, setTrucks_3days_150_Day3] = useState(0);
  const [TWO_Trips_Or_OK_3days_150_Day3, setTWO_Trips_Or_OK_3days_150_Day3] = useState(0);
  const [Double_drive_3days_150_Day3, setDouble_drive_3days_150_Day3] = useState(0);
  const [Destination_fee_3days_150_Day3, setDestination_fee_3days_150_Day3] = useState(0);
  const [Lower_Number_of_Hours_3days_150_Day3, setLower_Number_of_Hours_3days_150_Day3] = useState(0);
  const [Higher_Number_of_Hours_3days_150_Day3, setHigher_Number_of_Hours_3days_150_Day3] = useState(0);
  const [Flat_rate_Cash_3days_150_Day3, setFlat_rate_Cash_3days_150_Day3] = useState(0);
  const [Flat_rate_Card_3days_150_Day3, setFlat_rate_Card_3days_150_Day3] = useState(0);
  const [Total_Card_threeDays150Miles, setTotal_Card_threeDays150Miles] = useState(0);
  const [Total_Cash_threeDays150Miles, setTotal_Cash_threeDays150Miles] = useState(0);


  const [Movers_To_Pack_Day1, setMovers_To_Pack_Day1] = useState(0);
  const [Trucks_To_Pack_Day1, setTrucks_To_Pack_Day1] = useState(0);
  const [Driving_Time_Day1, setDriving_Time_Day1] = useState(0);
  const [Unloading_hours_Day1, setUnloading_hours_Day1] = useState(0);
  const [Loading_hours_Day_1, setLoading_hours_Day_1] = useState(0);
  const [Total_Hours_Day_1, setTotal_Hours_Day_1] = useState(0);
  const [Packing_Time_Day1, setPacking_Time_Day1] = useState(0);
  const [Wrapping_time_Day1, setWrapping_time_Day1] = useState(0);
  const [Adjusted_loading_time_Day2, setAdjusted_loading_time_Day2] = useState(0);
  const [Lower_Number_of_Hours_Day1, setLower_Number_of_Hours_Day1] = useState(0);
  const [Higher_Number_of_Hours_Day1, setHigher_Number_of_Hours_Day1] = useState(0);
  const [Lower_Number_of_Hours_Day2, setLower_Number_of_Hours_Day2] = useState(0);
  const [Higher_Number_of_Hours_Day2, setHigher_Number_of_Hours_Day2] = useState(0);
  const [Total_Cash_Day2, setTotal_Cash_Day2] = useState(0);
  const [Total_Card_Day2, setTotal_Card_Day2] = useState(0);
  const [Loading_hours_Day2, setLoading_hours_Day2] = useState(0);
  const [Movers_Day2, setMovers_Day2] = useState(0);
  const [Rate_cash_Movers_To_Unload_Day2, setRate_cash_Movers_To_Unload_Day2] = useState(0);
  const [Rate_card_Movers_To_Unload_Day2, setRate_card_Movers_To_Unload_Day2] = useState(0);
  const [Total_Destinationfee_Day2, setTotal_Destinationfee_Day2] = useState(0);
  const [Rate_cash_Movers_To_Load_Day2, setRate_cash_Movers_To_Load_Day2] = useState(0);
  const [Rate_card_Movers_To_Load_Day2, setRate_card_Movers_To_Load_Day2] = useState(0);
  const [Truck_fee_Day2, setTruck_fee_Day2] = useState(0);
  const [Rate_cash_Day2, setRate_cash_Day2] = useState(0);
  const [Rate_card_Day2, setRate_card_Day2] = useState(0);
  const [Rate_cash_Day1, setRate_cash_Day1] = useState(0);
  const [Rate_card_Day1, setRate_card_Day1] = useState(0);
  const [Truck_fee_Day1, setTruck_fee_Day1] = useState(0);
  const [Trucks_Day2, setTrucks_Day2] = useState(0);
  const [Unloading_hours_Day2, setUnloading_hours_Day2] = useState(0);
  const [Total_Hours_Day2, setTotal_Hours_Day2] = useState(0);
  const [Drivetime_Day2, setDrivetime_Day2] = useState(0);
  const [cubicf_Day1, setcubicf_Day1] = useState(0);
  const [Total_Cash_Day1, setTotal_Cash_Day1] = useState(0);
  const [Total_Card_Day1, setTotal_Card_Day1] = useState(0);
  const [Total_Card_2Days_Packing, setTotal_Card_2Days_Packing] = useState(0);
  const [Total_Cash_2Days_Packing, setTotal_Cash_2Days_Packing] = useState(0);
  const [BIGGEST_or_OK_Day2, setBIGGEST_or_OK_Day2] = useState('');




  const [Total_Hours_LONGOne_Day_Loading, setTotal_Hours_LONGOne_Day_Loading] = useState(0);
  const [Drivetime_LONGOne_Day, setDrivetime_LONGOne_Day] = useState(0);
  const [Movers_LONGOne_Day_Loading, setMovers_LONGOne_Day_Loading] = useState(0);
  const [Trucks_LONGOne_Day, setTrucks_LONGOne_Day] = useState(0);
  const [Total_Hours_LONGOne_Day_Loading_Lower_number, setTotal_Hours_LONGOne_Day_Loading_Lower_number] = useState(0);
  const [Total_Hours_LONGOne_Day_Unloading, setTotal_Hours_LONGOne_Day_Unloading] = useState(0);
  const [Movers_LONGOne_Day_Unloading, setMovers_LONGOne_Day_Unloading] = useState(0);
  const [Total_Hours_LONGOne_Day_Unloading_Lower_number, setTotal_Hours_LONGOne_Day_Unloading_Lower_number] = useState(0);
  const [Total_Card_LONGOne_Day, setTotal_Card_LONGOne_Day] = useState(0);
  const [Total_Cash_LONGOne_Day, setTotal_Cash_LONGOne_Day] = useState(0);
  const [Total_Card_LONGOne_Day_R, setTotal_Card_LONGOne_Day_R] = useState(0);
  const [Total_Cash_LONGOne_Day_R, setTotal_Cash_LONGOne_Day_R] = useState(0);
  const [Total_Card_Unpacking_Separate_Day_Long_and_OOS, setTotal_Card_Unpacking_Separate_Day_Long_and_OOS] = useState(0)

  const [Movers_To_Pack_LONG_Day1, setMovers_To_Pack_LONG_Day1] = useState(0);
  const [Rate_cash_LONG_Day1, setRate_cash_LONG_Day1] = useState(0);
  const [Rate_card_LONG_Day1, setRate_card_LONG_Day1] = useState(0);
  const [Truck_fee_LONG_Day1, setTruck_fee_LONG_Day1] = useState(0);
  const [Packing_Time_LONG_Day1, setPacking_Time_LONG_Day1] = useState(0);
  const [Wrapping_time_LONG_Day1, setWrapping_time_LONG_Day1] = useState(0);
  const [Total_Hours_LONG_Day1, setTotal_Hours_LONG_Day1] = useState(0);
  const [Lower_Number_of_Hours_LONG_Day1, setLower_Number_of_Hours_LONG_Day1] = useState(0);
  const [Higher_Number_of_Hours_LONG_Day1, setHigher_Number_of_Hours_LONG_Day1] = useState(0);
  const [Total_Card_LONG_Day1_Rounded, setTotal_Card_LONG_Day1_Rounded] = useState(0);
  const [Total_Card_LONG_Day1, setTotal_Card_LONG_Day1] = useState(0);
  const [Movers_LONG2DAYS_Loading, setMovers_LONG2DAYS_Loading] = useState(0);
  const [Drivetime_LONG2DAYS, setDrivetime_LONG2DAYS] = useState(0);
  const [Total_Hours_LONG2DAYS_Unloading, setTotal_Hours_LONG2DAYS_Unloading] = useState(0);
  const [Trucks_LONG2DAYS, setTrucks_LONG2DAYS] = useState(0);
  const [Total_Hours_LONG2DAYS_Loading_Lower_number, setTotal_Hours_LONG2DAYS_Loading_Lower_number] = useState(0);
  const [Total_Hours_LONG2DAYS_Loading, setTotal_Hours_LONG2DAYS_Loading] = useState(0);
  const [Movers_LONG2DAYS_Unloading, setMovers_LONG2DAYS_Unloading] = useState(0);
  const [Total_Hours_LONG2DAYS_Unloading_Lower_number, setTotal_Hours_LONG2DAYS_Unloading_Lower_number] = useState(0);
  const [Total_Card_LONG2DAYS, setTotal_Card_LONG2DAYS] = useState(0);
  const [Total_Card_LONG_Day2, setTotal_Card_LONG_Day2] = useState(0);
  const [Total_Card_LONG_Day2_R, setTotal_Card_LONG_Day2_R] = useState(0);
  const [Total_Card_LONG2DAYS_R, setTotal_Card_LONG2DAYS_R] = useState(0);
  const [Total_Cash_LONG2DAYS, setTotal_Cash_LONG2DAYS] = useState(0);
  const [Total_Cash_LONG2DAYS_R, setTotal_Cash_LONG2DAYS_R] = useState(0);
  const [Total_Cash_LONG_Day1, setTotal_Cash_LONG_Day1] = useState(0);

  const [Movers_OOSOne_Day_Loading, setMovers_OOSOne_Day_Loading] = useState(0);
  const [Rate_card_OOSOne_Day, setRate_card_OOSOne_Day] = useState(0);
  const [Total_Amount_Packing_OOSOne_Day, setTotal_Amount_Packing_OOSOne_Day] = useState(0);
  const [Packing_time_without_loading_OOSOne_Day, setPacking_time_without_loading_OOSOne_Day] = useState(0);
  const [Total_Hours_OOSOne_Day_Unloading, setTotal_Hours_OOSOne_Day_Unloading] = useState(0);
  const [Trucks_OOSOne_Day, setTrucks_OOSOne_Day] = useState(0);
  const [Total_Hours_OOSOne_Day_Loading_Lower_number, setTotal_Hours_OOSOne_Day_Loading_Lower_number] = useState(0);
  const [Drivetime_OOSOne_Day, setDrivetime_OOSOne_Day] = useState(0);
  const [Movers_OOSOne_Day_Unloading, setMovers_OOSOne_Day_Unloading] = useState(0);
  const [Total_Hours_OOSOne_Day_Unloading_Lower_number, setTotal_Hours_OOSOne_Day_Unloading_Lower_number] = useState(0);
  const [Total_Card_OOSOne_Day, setTotal_Card_OOSOne_Day] = useState(0);
  const [Total_Cash_OOSOne_Day, setTotal_Cash_OOSOne_Day] = useState(0);
  const [Total_Hours_OOSOne_Day_Loading, setTotal_Hours_OOSOne_Day_Loading] = useState(0);
  const [Rate_card_Unpacking_Same_Day_OOS, setRate_card_Unpacking_Same_Day_OOS] = useState(0);
  const [Unpacking_hours_same_day_OOSOne_Day, setUnpacking_hours_same_day_OOSOne_Day] = useState(0);
  const [Total_Card_Unpacking_Same_Day_OOS, setTotal_Card_Unpacking_Same_Day_OOS] = useState(0);


  const [Movers_To_Pack_OOS_Day1, setMovers_To_Pack_OOS_Day1] = useState(0)
  const [Rate_card_OOS_Day1, setRate_card_OOS_Day1] = useState(0)
  const [Packing_Time_OOS_Day1, setPacking_Time_OOS_Day1] = useState(0)
  const [Wrapping_time_OOS_Day1, setWrapping_time_OOS_Day1] = useState(0)
  const [Total_Hours_OOS2DAYS_Loading, setTotal_Hours_OOS2DAYS_Loading] = useState(0)
  const [Total_Hours_OOS_Day1, setTotal_Hours_OOS_Day1] = useState(0)
  const [Lower_Number_of_Hours_OOS_Day1, setLower_Number_of_Hours_OOS_Day1] = useState(0)
  const [Higher_Number_of_Packing_Hours_OOS_Day1, setHigher_Number_of_Packing_Hours_OOS_Day1] = useState(0)
  const [Higher_Number_of_Hours_OOS_Day1, setHigher_Number_of_Hours_OOS_Day1] = useState(0)
  const [Total_Card_Packing_OOS_Day1, setTotal_Card_Packing_OOS_Day1] = useState(0)
  const [Total_Card_Packing_OOS_Day1_Rounded, setTotal_Card_Packing_OOS_Day1_Rounded] = useState(0)
  const [Movers_OOS2DAYS_Loading, setMovers_OOS2DAYS_Loading] = useState(0)
  const [Total_Hours_OOS2DAYS_Unloading, setTotal_Hours_OOS2DAYS_Unloading] = useState(0)
  const [Total_Card_OOS2DAYS, setTotal_Card_OOS2DAYS] = useState(0)
  const [Total_Amount_MilesFactor_OOS2DAYS, setTotal_Amount_MilesFactor_OOS2DAYS] = useState(0)
  const [Trucks_OOS2DAYS, setTrucks_OOS2DAYS] = useState(0)
  const [Total_Hours_OOS2DAYS_Loading_Lower_number, setTotal_Hours_OOS2DAYS_Loading_Lower_number] = useState(0)
  const [Total_Hours_OOS2DAYS_Unloading_Lower_number, setTotal_Hours_OOS2DAYS_Unloading_Lower_number] = useState(0)
  const [Total_Cash_OOS2DAYS, setTotal_Cash_OOS2DAYS] = useState(0)
  const [Rate_card_Unpacking_Same_Day_OOS_2Days, setRate_card_Unpacking_Same_Day_OOS_2Days] = useState(0);
  const [Unpacking_hours_same_day_OOS2DAYS, setUnpacking_hours_same_day_OOS2DAYS] = useState(0);
  const [Total_Card_Unpacking_Same_Day_OOS_2Days, setTotal_Card_Unpacking_Same_Day_OOS_2Days] = useState(0);
  const [Trucks_To_Pack_OOS_Day1, setTrucks_To_Pack_OOS_Day1] = useState(0);
  const [Truckloads_to_move, setTruckloads_to_move] = useState(0);
  const [Total_cubes_Within_Building, setTotal_cubes_Within_Building] = useState(0);
  const templateSavingStatus = useSelector(getTemplateSavingStatus);


  const [editorState, setEditorState] = useState(() => {
    if (templateEditorState && Object.keys(templateEditorState).length) {
      return EditorState.createWithContent(convertFromRaw(JSON.parse(templateEditorState)));
    }
    else {
      return EditorState.createEmpty();
    }
  }
  );


  useEffect(() => {

    socket.on('Total_cubes', (data) => { setTotal_cubes(data); dispatch(setTotal_cubesG(data)); });
    socket.on('Total_small_boxes', (data) => {
      setTotal_small_boxes(data)
      dispatch(setTotalSmallBoxes(data));
    });
    socket.on('Total_medium_boxes', (data) => {
      setTotal_medium_boxes(data)
      dispatch(setTotalMediumBoxes(data));
    });
    socket.on('Total_fragile_boxes', (data) => {
      setTotal_fragile_boxes(data)
      dispatch(setTotalFragileBoxes(data));
    });
    socket.on('Total_box_cubes', (data) => {
      setTotal_box_cubes(data)
    });
    socket.on('Stop_1_Truckload', (data) => {
      setStop_1_Truckload(data)
    });
    socket.on('Stop_CN_Truckload', (data) => {
      setStop_CN_Truckload(data)
    });
    socket.on('Charge_per_mile_amount', (data) => {
      setCharge_per_mile_amount(data)
    });
    socket.on('Movers_to_load_One_Day_hours', (data) => {
      setMovers_to_load_One_Day_hours(data)
    });
    socket.on('Loading_hours_One_Day', (data) => {
      setLoading_hours_One_Day(data)
    });
    socket.on('Adjusted_loading_time_One_Day', (data) => {
      setAdjusted_loading_time_One_Day(data)
    });
    socket.on('Drivetime_One_Day', (data) => {
      setDrivetime_One_Day(data)
    });
    socket.on('Movers_to_unload_One_Day_hours', (data) => {
      setMovers_to_unload_One_Day_hours(data)
    });
    socket.on('Unloading_hours_One_Day', (data) => {
      setUnloading_hours_One_Day(data)
    });
    socket.on('Total_Hours_One_Day', (data) => {
      setTotal_Hours_One_Day(data)
    });
    socket.on('Truckloads_to_move', (data) => {
      setTruckloads_to_move(data)
    });
    socket.on('Total_cubes_Within_Building', (data) => {
      setTotal_cubes_Within_Building(data)
    });
    socket.on('Truckloads', (data) => {
      setTruckloads(data)
    });
    socket.on('Total_Destinationfee_One_Day', (data) => {
      setTotal_Destinationfee_One_Day(data)
    });
    socket.on('Rate_cash_Movers_To_Load_One_Day', (data) => {
      setRate_cash_Movers_To_Load_One_Day(data)
    });
    socket.on('Rate_card_Movers_To_Load_One_Day', (data) => {
      setRate_card_Movers_To_Load_One_Day(data)
    });
    socket.on('Truck_fee_One_Day', (data) => {
      setTruck_fee_One_Day(data)
    });
    socket.on('Movers_One_Day', (data) => {
      setMovers_One_Day(data)
    });
    socket.on('Rate_cash_One_Day', (data) => {
      setRate_cash_One_Day(data)
    });
    socket.on('Rate_card_One_Day', (data) => {
      setRate_card_One_Day(data)
    });
    socket.on('Rate_cash_Movers_To_Unload_One_Day', (data) => {
      setRate_cash_Movers_To_Unload_One_Day(data)
    });
    socket.on('Rate_card_Movers_To_Unload_One_Day', (data) => {
      setRate_card_Movers_To_Unload_One_Day(data)
    });
    socket.on('Trucks_One_Day', (data) => {
      setTrucks_One_Day(data)
    });
    socket.on('TWO_Trips_Or_OK', (data) => {
      setTWO_Trips_Or_OK(data)
    });
    socket.on('BIGGEST_or_OK', (data) => {
      setBIGGEST_or_OK(data)
    });
    socket.on('Double_drive', (data) => {
      setDouble_drive(data)
    });
    socket.on('Lower_Number_of_Hours_One_Day', (data) => {
      setLower_Number_of_Hours_One_Day(data)
    });
    socket.on('Higher_Number_of_Hours_One_Day', (data) => {
      setHigher_Number_of_Hours_One_Day(data)
    });
    socket.on('Heavy_fee', (data) => {
      setHeavy_fee(data)
    });
    socket.on('Packing_kit_Show', (data) => {
      setPacking_kit_Show(data)
    });
    socket.on('Packing_kit_total_amount_with_taxes', (data) => {
      setPacking_kit_total_amount_with_taxes(data)
    });
    socket.on('Total_Card_One_day', (data) => {
      setTotal_Card_One_day(data)
    });
    socket.on('Total_Cash_One_day', (data) => {
      setTotal_Cash_One_day(data)
    });

    socket.on('Flat_rate_One_Day', (data) => setFlat_rate_One_Day(data));
    socket.on('Flat_rate_One_Day_cash', (data) => setFlat_rate_One_Day_cash(data));
    socket.on('Packing_Charge', (data) => setPacking_Charge(data));
    socket.on('UnPacking_Charge', (data) => setUnPacking_Charge(data));

    socket.on('Combined_mileage', (data) => { setCombined_mileage(data) });
    socket.on('Trucks_Unpacking_Separate_Day', (data) => setTrucks_Unpacking_Separate_Day(data));
    socket.on('Movers_Unpacking_Separate_Day', (data) => setMovers_Unpacking_Separate_Day(data));
    socket.on('Unpacking_Separate_Day_Lower_hours', (data) => setUnpacking_Separate_Day_Lower_hours(data));
    socket.on('Unpacking_Separate_Day_Higher_hours', (data) => setUnpacking_Separate_Day_Higher_hours(data));
    socket.on('Total_Card_Unpacking_Separate_Day', (data) => setTotal_Card_Unpacking_Separate_Day(data));
    socket.on('Total_Cash_Unpacking_Separate_Day', (data) => setTotal_Cash_Unpacking_Separate_Day(data));
    socket.on('Rate_cash_Unpacking_Separate_Day', (data) => setRate_cash_Unpacking_Separate_Day(data));
    socket.on('Rate_card_Unpacking_Separate_Day', (data) => setRate_card_Unpacking_Separate_Day(data));
    socket.on('Truck_fee_Unpacking_Separate_Day', (data) => setTruck_fee_Unpacking_Separate_Day(data));
    socket.on('Flat_rate_Unpacking_Separate_Day', (data) => setFlat_rate_Unpacking_Separate_Day(data));
    socket.on('Flat_rate_Unpacking_Separate_Day_cash', (data) => setFlat_rate_Unpacking_Separate_Day_cash(data));



    socket.on('Time_to_pack', (data) => setTime_to_pack(data));

    socket.on('Total_box_cubes', (data) => setTotal_box_cubes(data));
    socket.on('Truckloads', (data) => setTruckloads(data));
    socket.on('Stop_CN_Truckload', (data) => setStop_CN_Truckload(data));
    socket.on('Stop_1_Truckload', (data) => setStop_1_Truckload(data));
    socket.on('BIGGEST_or_OK', (data) => setBIGGEST_or_OK(data));
    socket.on('Heavy_fee', (data) => setHeavy_fee(data));
    socket.on('Packing_kit_Show', (data) => setPacking_kit_Show(data));
    socket.on('Packing_kit_total_amount_with_taxes', (data) => setPacking_kit_total_amount_with_taxes(data));
    socket.on('TWO_Trips_Or_OK_Day2', (data) => setTWO_Trips_Or_OK_Day2(data));
    socket.on('Loading_hours_150miles', (data) => setLoading_hours_150miles(data));
    socket.on('Total_Hours_150miles_Day1', (data) => setTotal_Hours_150miles_Day1(data));
    socket.on('Unloading_hours_150miles', (data) => setUnloading_hours_150miles(data));
    socket.on('Total_Hours_150miles_Day2', (data) => setTotal_Hours_150miles_Day2(data));
    socket.on('Movers_150miles_Day1', (data) => setMovers_150miles_Day1(data));
    socket.on('Movers_150miles_Day2', (data) => setMovers_150miles_Day2(data));
    socket.on('Total_Destinationfee_150miles', (data) => setTotal_Destinationfee_150miles(data));
    socket.on('Double_drive_150miles_Day1', (data) => setDouble_drive_150miles_Day1(data));
    socket.on('Lower_Number_of_Hours_150miles_Day1', (data) => setLower_Number_of_Hours_150miles_Day1(data));
    socket.on('Higher_Number_of_Hours_150miles_Day2', (data) => setHigher_Number_of_Hours_150miles_Day2(data));
    socket.on('Higher_Number_of_Hours_150miles_Day1', (data) => setHigher_Number_of_Hours_150miles_Day1(data));
    socket.on('Trucks_150miles_Day2', (data) => setTrucks_150miles_Day2(data));
    socket.on('Double_drive_150miles_Day2', (data) => setDouble_drive_150miles_Day2(data));
    socket.on('Lower_Number_of_Hours_150miles_Day2', (data) => setLower_Number_of_Hours_150miles_Day2(data));
    socket.on('Total_Card_150miles_2Days', (data) => setTotal_Card_150miles_2Days(data));
    socket.on('Total_Cash_150miles_Day2', (data) => setTotal_Cash_150miles_Day2(data));
    socket.on('Total_Cash_150miles_2Days', (data) => setTotal_Cash_150miles_2Days(data));
    socket.on('Driving_time_with_DDT_150miles_Day1', (data) => setDriving_time_with_DDT_150miles_Day1(data));
    socket.on('Driving_time_with_DDT_150miles_Day2', (data) => setDriving_time_with_DDT_150miles_Day2(data));
    socket.on('Rate_cash_150miles_Day1', (data) => setRate_cash_150miles_Day1(data));
    socket.on('Total_Cash_150miles_Day1', (data) => setTotal_Cash_150miles_Day1(data));
    socket.on('Total_Card_150miles_Day1', (data) => setTotal_Card_150miles_Day1(data));
    socket.on('Total_Card_150miles_Day2', (data) => setTotal_Card_150miles_Day2(data));
    socket.on('Rate_card_150miles_Day1', (data) => setRate_card_150miles_Day1(data));
    socket.on('Trucks_150miles_Day1', (data) => setTrucks_150miles_Day1(data));
    socket.on('Destination_fee_150miles', (data) => setDestination_fee_150miles(data));
    socket.on('Truck_fee_150miles_Day1', (data) => setTruck_fee_150miles_Day1(data));
    socket.on('Rate_cash_150miles_Day2', (data) => setRate_cash_150miles_Day2(data));
    socket.on('Rate_card_150miles_Day2', (data) => setRate_card_150miles_Day2(data));
    socket.on('Truck_fee_150miles_Day2', (data) => setTruck_fee_150miles_Day2(data));
    socket.on('Trucks_Unpacking_Separate_Day', (data) => setTrucks_Unpacking_Separate_Day(data));
    socket.on('Movers_Unpacking_Separate_Day', (data) => setMovers_Unpacking_Separate_Day(data));
    socket.on('Unpacking_Separate_Day_Lower_hours', (data) => setUnpacking_Separate_Day_Lower_hours(data));
    socket.on('Unpacking_Separate_Day_Higher_hours', (data) => setUnpacking_Separate_Day_Higher_hours(data));
    socket.on('Total_Card_Unpacking_Separate_Day', (data) => setTotal_Card_Unpacking_Separate_Day(data));
    socket.on('Total_Cash_Unpacking_Separate_Day', (data) => setTotal_Cash_Unpacking_Separate_Day(data));
    socket.on('Rate_cash_Unpacking_Separate_Day', (data) => setRate_cash_Unpacking_Separate_Day(data));
    socket.on('Rate_card_Unpacking_Separate_Day', (data) => setRate_card_Unpacking_Separate_Day(data));
    socket.on('Truck_fee_Unpacking_Separate_Day', (data) => setTruck_fee_Unpacking_Separate_Day(data));
    socket.on('Combined_mileage', (data) => { setCombined_mileage(data) });

    socket.on('Total_box_cubes', (data) => {
      setTotal_box_cubes(data)
    });
    socket.on('Truckloads', (data) => {
      setTruckloads(data)
    });
    socket.on('Stop_CN_Truckload', (data) => {
      setStop_CN_Truckload(data)
    });
    socket.on('Stop_1_Truckload', (data) => {
      setStop_1_Truckload(data)
    });
    socket.on('Double_drive', (data) => {
      setDouble_drive(data)
    });
    socket.on('Heavy_fee', (data) => {
      setHeavy_fee(data)
    });
    socket.on('Packing_kit_Show', (data) => {
      setPacking_kit_Show(data)
    });
    socket.on('Packing_kit_total_amount_with_taxes', (data) => {
      setPacking_kit_total_amount_with_taxes(data)
    });
    socket.on('Movers_To_Pack_Day1', (data) => {
      setMovers_To_Pack_Day1(data)
    });
    socket.on('Trucks_To_Pack_Day1', (data) => {
      setTrucks_To_Pack_Day1(data)
    });
    socket.on('Driving_Time_Day1', (data) => {
      setDriving_Time_Day1(data)
    });
    socket.on('Unloading_hours_Day1', (data) => {
      setUnloading_hours_Day1(data)
    });
    socket.on('Loading_hours_Day_1', (data) => {
      setLoading_hours_Day_1(data)
    });
    socket.on('Total_Hours_Day_1', (data) => {
      setTotal_Hours_Day_1(data)
    });
    socket.on('Packing_Time_Day1', (data) => {
      setPacking_Time_Day1(data)
    });
    socket.on('Wrapping_time_Day1', (data) => {
      setWrapping_time_Day1(data)
    });
    socket.on('Adjusted_loading_time_Day2', (data) => {
      setAdjusted_loading_time_Day2(data)
    });
    socket.on('Lower_Number_of_Hours_Day1', (data) => {
      setLower_Number_of_Hours_Day1(data)
    });
    socket.on('Higher_Number_of_Hours_Day1', (data) => {
      setHigher_Number_of_Hours_Day1(data)
    });
    socket.on('TWO_Trips_Or_OK_Day2', (data) => {
      setTWO_Trips_Or_OK_Day2(data)
    });
    socket.on('Lower_Number_of_Hours_Day2', (data) => {
      setLower_Number_of_Hours_Day2(data)
    });
    socket.on('Higher_Number_of_Hours_Day2', (data) => {
      setHigher_Number_of_Hours_Day2(data)
    });
    socket.on('Total_Cash_Day2', (data) => {
      setTotal_Cash_Day2(data)
    });
    socket.on('Total_Card_Day2', (data) => {
      setTotal_Card_Day2(data)
    });
    socket.on('Loading_hours_Day2', (data) => {
      setLoading_hours_Day2(data)
    });
    socket.on('Movers_Day2', (data) => {
      setMovers_Day2(data)
    });
    socket.on('Rate_cash_Movers_To_Unload_Day2', (data) => {
      setRate_cash_Movers_To_Unload_Day2(data)
    });
    socket.on('Rate_card_Movers_To_Unload_Day2', (data) => {
      setRate_card_Movers_To_Unload_Day2(data)
    });
    socket.on('Total_Destinationfee_Day2', (data) => {
      setTotal_Destinationfee_Day2(data)
    });
    socket.on('Rate_cash_Movers_To_Load_Day2', (data) => {
      setRate_cash_Movers_To_Load_Day2(data)
    });
    socket.on('Rate_card_Movers_To_Load_Day2', (data) => {
      setRate_card_Movers_To_Load_Day2(data)
    });
    socket.on('Truck_fee_Day2', (data) => {
      setTruck_fee_Day2(data)
    });
    socket.on('Rate_cash_Day2', (data) => {
      setRate_cash_Day2(data)
    });
    socket.on('Rate_card_Day2', (data) => {
      setRate_card_Day2(data)
    });
    socket.on('Rate_cash_Day1', (data) => {
      setRate_cash_Day1(data)
    });
    socket.on('Rate_card_Day1', (data) => {
      setRate_card_Day1(data)
    });
    socket.on('Truck_fee_Day1', (data) => {
      setTruck_fee_Day1(data)
    });
    socket.on('Trucks_Day2', (data) => {
      setTrucks_Day2(data)
    });
    socket.on('Unloading_hours_Day2', (data) => {
      setUnloading_hours_Day2(data)
    });
    socket.on('Total_Hours_Day2', (data) => {
      setTotal_Hours_Day2(data)
    });
    socket.on('Drivetime_Day2', (data) => {
      setDrivetime_Day2(data)
    });
    socket.on('cubicf_Day1', (data) => {
      setcubicf_Day1(data)
    });
    socket.on('Total_Cash_Day1', (data) => {
      setTotal_Cash_Day1(data)
    });
    socket.on('Total_Card_Day1', (data) => {
      setTotal_Card_Day1(data)
    });
    socket.on('Total_Card_2Days_Packing', (data) => {
      setTotal_Card_2Days_Packing(data)
    });
    socket.on('Total_Cash_2Days_Packing', (data) => {
      setTotal_Cash_2Days_Packing(data)
    });
    socket.on('BIGGEST_or_OK_Day2', (data) => {
      setBIGGEST_or_OK_Day2(data)
    });

    socket.on('Total_box_cubes', (data) => setTotal_box_cubes(data));
    socket.on('Truckloads', (data) => setTruckloads(data));
    socket.on('Stop_CN_Truckload', (data) => setStop_CN_Truckload(data));
    socket.on('Stop_1_Truckload', (data) => setStop_1_Truckload(data));
    socket.on('BIGGEST_or_OK', (data) => setBIGGEST_or_OK(data));
    socket.on('Heavy_fee', (data) => setHeavy_fee(data));
    socket.on('Packing_kit_Show', (data) => setPacking_kit_Show(data));
    socket.on('Packing_kit_total_amount_with_taxes', (data) => setPacking_kit_total_amount_with_taxes(data));
    socket.on('Total_Hours_LONGOne_Day_Loading', (data) => setTotal_Hours_LONGOne_Day_Loading(data));
    socket.on('Drivetime_LONGOne_Day', (data) => setDrivetime_LONGOne_Day(data));
    socket.on('Movers_LONGOne_Day_Loading', (data) => setMovers_LONGOne_Day_Loading(data));
    socket.on('Trucks_LONGOne_Day', (data) => setTrucks_LONGOne_Day(data));
    socket.on('Total_Hours_LONGOne_Day_Loading_Lower_number', (data) => setTotal_Hours_LONGOne_Day_Loading_Lower_number(data));
    socket.on('Total_Hours_LONGOne_Day_Unloading', (data) => setTotal_Hours_LONGOne_Day_Unloading(data));
    socket.on('Movers_LONGOne_Day_Unloading', (data) => setMovers_LONGOne_Day_Unloading(data));
    socket.on('Total_Hours_LONGOne_Day_Unloading_Lower_number', (data) => setTotal_Hours_LONGOne_Day_Unloading_Lower_number(data));
    socket.on('Total_Card_LONGOne_Day', (data) => setTotal_Card_LONGOne_Day(data));
    socket.on('Total_Cash_LONGOne_Day', (data) => setTotal_Cash_LONGOne_Day(data));
    socket.on('Total_Card_LONGOne_Day_R', (data) => setTotal_Card_LONGOne_Day_R(data));
    socket.on('Total_Cash_LONGOne_Day_R', (data) => setTotal_Cash_LONGOne_Day_R(data));
    socket.on('Trucks_Unpacking_Separate_Day', (data) => setTrucks_Unpacking_Separate_Day(data))
    socket.on('Movers_Unpacking_Separate_Day', (data) => setMovers_Unpacking_Separate_Day(data))
    socket.on('Unpacking_Separate_Day_Lower_hours', (data) => setUnpacking_Separate_Day_Lower_hours(data))
    socket.on('Unpacking_Separate_Day_Higher_hours', (data) => setUnpacking_Separate_Day_Higher_hours(data))
    socket.on('Rate_cash_Unpacking_Separate_Day', (data) => setRate_cash_Unpacking_Separate_Day(data))
    socket.on('Rate_card_Unpacking_Separate_Day', (data) => setRate_card_Unpacking_Separate_Day(data))
    socket.on('Total_Card_Unpacking_Separate_Day_Long_and_OOS', (data) => setTotal_Card_Unpacking_Separate_Day_Long_and_OOS(data))
    socket.on('Truck_fee_Unpacking_Separate_Day', (data) => setTruck_fee_Unpacking_Separate_Day(data))


    socket.on('Total_box_cubes', (data) => setTotal_box_cubes(data));
    socket.on('Truckloads', (data) => setTruckloads(data));
    socket.on('Stop_CN_Truckload', (data) => setStop_CN_Truckload(data));
    socket.on('Stop_1_Truckload', (data) => setStop_1_Truckload(data));
    socket.on('Heavy_fee', (data) => setHeavy_fee(data));
    socket.on('Packing_kit_Show', (data) => setPacking_kit_Show(data));
    socket.on('Packing_kit_total_amount_with_taxes', (data) => setPacking_kit_total_amount_with_taxes(data));
    socket.on('Movers_To_Pack_LONG_Day1', (data) => setMovers_To_Pack_LONG_Day1(data));
    socket.on('Rate_cash_LONG_Day1', (data) => setRate_cash_LONG_Day1(data));
    socket.on('Rate_card_LONG_Day1', (data) => setRate_card_LONG_Day1(data));
    socket.on('Truck_fee_LONG_Day1', (data) => setTruck_fee_LONG_Day1(data));
    socket.on('Packing_Time_LONG_Day1', (data) => setPacking_Time_LONG_Day1(data));
    socket.on('Wrapping_time_LONG_Day1', (data) => setWrapping_time_LONG_Day1(data));
    socket.on('Total_Hours_LONG_Day1', (data) => setTotal_Hours_LONG_Day1(data));
    socket.on('Lower_Number_of_Hours_LONG_Day1', (data) => setLower_Number_of_Hours_LONG_Day1(data));
    socket.on('Higher_Number_of_Hours_LONG_Day1', (data) => setHigher_Number_of_Hours_LONG_Day1(data));
    socket.on('Total_Card_LONG_Day1_Rounded', (data) => setTotal_Card_LONG_Day1_Rounded(data));
    socket.on('Total_Card_LONG_Day1', (data) => setTotal_Card_LONG_Day1(data));
    socket.on('Movers_LONG2DAYS_Loading', (data) => setMovers_LONG2DAYS_Loading(data));
    socket.on('Drivetime_LONG2DAYS', (data) => setDrivetime_LONG2DAYS(data));
    socket.on('Total_Hours_LONG2DAYS_Unloading', (data) => setTotal_Hours_LONG2DAYS_Unloading(data));
    socket.on('Trucks_LONG2DAYS', (data) => setTrucks_LONG2DAYS(data));
    socket.on('Total_Hours_LONG2DAYS_Loading_Lower_number', (data) => setTotal_Hours_LONG2DAYS_Loading_Lower_number(data));
    socket.on('Total_Hours_LONG2DAYS_Loading', (data) => setTotal_Hours_LONG2DAYS_Loading(data));
    socket.on('Movers_LONG2DAYS_Unloading', (data) => setMovers_LONG2DAYS_Unloading(data));
    socket.on('Total_Hours_LONG2DAYS_Unloading_Lower_number', (data) => setTotal_Hours_LONG2DAYS_Unloading_Lower_number(data));
    socket.on('Total_Card_LONG2DAYS', (data) => setTotal_Card_LONG2DAYS(data));
    socket.on('Total_Card_LONG_Day2', (data) => setTotal_Card_LONG_Day2(data));
    socket.on('Total_Card_LONG_Day2_R', (data) => setTotal_Card_LONG_Day2_R(data));
    socket.on('Total_Card_LONG2DAYS_R', (data) => setTotal_Card_LONG2DAYS_R(data));
    socket.on('Total_Cash_LONG2DAYS', (data) => setTotal_Cash_LONG2DAYS(data));
    socket.on('Total_Cash_LONG2DAYS_R', (data) => setTotal_Cash_LONG2DAYS_R(data));
    socket.on('Total_Cash_LONG_Day1', (data) => setTotal_Cash_LONG_Day1(data));
    socket.on('Trucks_Unpacking_Separate_Day', (data) => setTrucks_Unpacking_Separate_Day(data))
    socket.on('Movers_Unpacking_Separate_Day', (data) => setMovers_Unpacking_Separate_Day(data))
    socket.on('Unpacking_Separate_Day_Lower_hours', (data) => setUnpacking_Separate_Day_Lower_hours(data))
    socket.on('Unpacking_Separate_Day_Higher_hours', (data) => setUnpacking_Separate_Day_Higher_hours(data))
    socket.on('Rate_cash_Unpacking_Separate_Day', (data) => setRate_cash_Unpacking_Separate_Day(data))
    socket.on('Rate_card_Unpacking_Separate_Day', (data) => setRate_card_Unpacking_Separate_Day(data))
    socket.on('Total_Card_Unpacking_Separate_Day_Long_and_OOS', (data) => setTotal_Card_Unpacking_Separate_Day_Long_and_OOS(data))
    socket.on('Truck_fee_Unpacking_Separate_Day', (data) => setTruck_fee_Unpacking_Separate_Day(data))
    socket.on('Combined_mileage', (data) => { setCombined_mileage(data) });

    socket.on('Movers_OOSOne_Day_Loading', (data) => setMovers_OOSOne_Day_Loading(data));
    socket.on('Rate_card_OOSOne_Day', (data) => setRate_card_OOSOne_Day(data));
    socket.on('Total_Amount_Packing_OOSOne_Day', (data) => setTotal_Amount_Packing_OOSOne_Day(data));
    socket.on('Packing_time_without_loading_OOSOne_Day', (data) => setPacking_time_without_loading_OOSOne_Day(data));
    socket.on('Total_Hours_OOSOne_Day_Unloading', (data) => setTotal_Hours_OOSOne_Day_Unloading(data));
    socket.on('Trucks_OOSOne_Day', (data) => setTrucks_OOSOne_Day(data));
    socket.on('Total_Hours_OOSOne_Day_Loading_Lower_number', (data) => setTotal_Hours_OOSOne_Day_Loading_Lower_number(data));
    socket.on('Drivetime_OOSOne_Day', (data) => setDrivetime_OOSOne_Day(data));
    socket.on('Movers_OOSOne_Day_Unloading', (data) => setMovers_OOSOne_Day_Unloading(data));
    socket.on('Total_Hours_OOSOne_Day_Unloading_Lower_number', (data) => setTotal_Hours_OOSOne_Day_Unloading_Lower_number(data));
    socket.on('Total_Card_OOSOne_Day', (data) => setTotal_Card_OOSOne_Day(data));
    socket.on('Total_Cash_OOSOne_Day', (data) => setTotal_Cash_OOSOne_Day(data));
    socket.on('Total_Hours_OOSOne_Day_Loading', (data) => setTotal_Hours_OOSOne_Day_Loading(data));
    socket.on('Rate_card_Unpacking_Same_Day_OOS', (data) => setRate_card_Unpacking_Same_Day_OOS(data));
    socket.on('Unpacking_hours_same_day_OOSOne_Day', (data) => setUnpacking_hours_same_day_OOSOne_Day(data));
    socket.on('Total_Card_Unpacking_Same_Day_OOS', (data) => setTotal_Card_Unpacking_Same_Day_OOS(data));



    socket.on('Movers_To_Pack_OOS_Day1', (data) => setMovers_To_Pack_OOS_Day1(data))
    socket.on('Rate_card_OOS_Day1', (data) => setRate_card_OOS_Day1(data))
    socket.on('Packing_Time_OOS_Day1', (data) => setPacking_Time_OOS_Day1(data))
    socket.on('Wrapping_time_OOS_Day1', (data) => setWrapping_time_OOS_Day1(data))
    socket.on('Total_Hours_OOS2DAYS_Loading', (data) => setTotal_Hours_OOS2DAYS_Loading(data))
    socket.on('Total_Hours_OOS_Day1', (data) => setTotal_Hours_OOS_Day1(data))
    socket.on('Lower_Number_of_Hours_OOS_Day1', (data) => setLower_Number_of_Hours_OOS_Day1(data))
    socket.on('Higher_Number_of_Packing_Hours_OOS_Day1', (data) => setHigher_Number_of_Packing_Hours_OOS_Day1(data))
    socket.on('Higher_Number_of_Hours_OOS_Day1', (data) => setHigher_Number_of_Hours_OOS_Day1(data))
    socket.on('Total_Card_Packing_OOS_Day1', (data) => setTotal_Card_Packing_OOS_Day1(data))
    socket.on('Total_Card_Packing_OOS_Day1_Rounded', (data) => setTotal_Card_Packing_OOS_Day1_Rounded(data))
    socket.on('Movers_OOS2DAYS_Loading', (data) => setMovers_OOS2DAYS_Loading(data))
    socket.on('Drivetime_OOSOne_Day', (data) => setDrivetime_OOSOne_Day(data))
    socket.on('Total_Hours_OOS2DAYS_Unloading', (data) => setTotal_Hours_OOS2DAYS_Unloading(data))
    socket.on('Total_Card_OOS2DAYS', (data) => setTotal_Card_OOS2DAYS(data))
    socket.on('Total_Amount_MilesFactor_OOS2DAYS', (data) => setTotal_Amount_MilesFactor_OOS2DAYS(data))
    socket.on('Trucks_OOS2DAYS', (data) => setTrucks_OOS2DAYS(data))
    socket.on('Total_Hours_OOS2DAYS_Loading_Lower_number', (data) => setTotal_Hours_OOS2DAYS_Loading_Lower_number(data))
    socket.on('Total_Hours_OOS2DAYS_Unloading_Lower_number', (data) => setTotal_Hours_OOS2DAYS_Unloading_Lower_number(data))
    socket.on('Total_Cash_OOS2DAYS', (data) => setTotal_Cash_OOS2DAYS(data))
    socket.on('Rate_card_Unpacking_Same_Day_OOS_2Days', (data) => setRate_card_Unpacking_Same_Day_OOS_2Days(data));
    socket.on('Unpacking_hours_same_day_OOS2DAYS', (data) => setUnpacking_hours_same_day_OOS2DAYS(data));
    socket.on('Total_Card_Unpacking_Same_Day_OOS_2Days', (data) => setTotal_Card_Unpacking_Same_Day_OOS_2Days(data));
    socket.on('Trucks_To_Pack_OOS_Day1', (data) => setTrucks_To_Pack_OOS_Day1(data));


    socket.on('Flat_rate_One_Day', (data) => setFlat_rate_One_Day(data));
    socket.on('Flat_rate_One_Day_cash', (data) => setFlat_rate_One_Day_cash(data));



    socket.on('Total_Cash_threeDays150Miles', (data) => { setTotal_Cash_threeDays150Miles(data) });
    socket.on('Total_Card_threeDays150Miles', (data) => { setTotal_Card_threeDays150Miles(data) });
    socket.on('Trucks_3days_150_Day3', (data) => { setTrucks_3days_150_Day3(data) });
    socket.on('UnPacking_Charge', (data) => { setUnPacking_Charge(data) });
    socket.on('Flat_rate_Unpacking_Separate_Day_cash', (data) => { setFlat_rate_Unpacking_Separate_Day_cash(data) });
    socket.on('Flat_rate_Unpacking_Separate_Day', (data) => { setFlat_rate_Unpacking_Separate_Day(data) });
    socket.on('Truck_fee_Unpacking_Separate_Day', (data) => { setTruck_fee_Unpacking_Separate_Day(data) });
    socket.on('Rate_card_Unpacking_Separate_Day', (data) => { setRate_card_Unpacking_Separate_Day(data) });
    socket.on('Rate_cash_Unpacking_Separate_Day', (data) => { setRate_cash_Unpacking_Separate_Day(data) });
    socket.on('Total_Cash_Unpacking_Separate_Day', (data) => { setTotal_Cash_Unpacking_Separate_Day(data) });
    socket.on('Total_Card_Unpacking_Separate_Day', (data) => { setTotal_Card_Unpacking_Separate_Day(data) });
    socket.on('Unpacking_Separate_Day_Higher_hours', (data) => { setUnpacking_Separate_Day_Higher_hours(data) });
    socket.on('Unpacking_Separate_Day_Lower_hours', (data) => { setUnpacking_Separate_Day_Lower_hours(data) });
    socket.on('Movers_Unpacking_Separate_Day', (data) => { setMovers_Unpacking_Separate_Day(data) });
    socket.on('Trucks_Unpacking_Separate_Day', (data) => { setTrucks_Unpacking_Separate_Day(data) });
    socket.on('Flat_rate_Card_3days_150_Day3', (data) => { setFlat_rate_Card_3days_150_Day3(data) });
    socket.on('Flat_rate_Cash_3days_150_Day3', (data) => { setFlat_rate_Cash_3days_150_Day3(data) });
    socket.on('Higher_Number_of_Hours_3days_150_Day3', (data) => { setHigher_Number_of_Hours_3days_150_Day3(data) });
    socket.on('Heavy_fee', (data) => { setHeavy_fee(data) });
    socket.on('Lower_Number_of_Hours_3days_150_Day3', (data) => { setLower_Number_of_Hours_3days_150_Day3(data) });
    socket.on('Destination_fee_3days_150_Day3', (data) => { setDestination_fee_3days_150_Day3(data) });
    socket.on('Double_drive_3days_150_Day3', (data) => { setDouble_drive_3days_150_Day3(data) });
    socket.on('TWO_Trips_Or_OK_3days_150_Day3', (data) => { setTWO_Trips_Or_OK_3days_150_Day3(data) });
    socket.on('BIGGEST_or_OK', (data) => { setBIGGEST_or_OK(data) });
    socket.on('Truck_fee_3days_150_Day3', (data) => { setTruck_fee_3days_150_Day3(data) });
    socket.on('Rate_card_3days_150_Day3', (data) => { setRate_card_3days_150_Day3(data) });
    socket.on('Rate_cash_3days_150_Day3', (data) => { setRate_cash_3days_150_Day3(data) });
    socket.on('Movers_3days_150_Day3', (data) => { setMovers_3days_150_Day3(data) });
    socket.on('Higher_Number_of_Hours_3days_150_Day2', (data) => { setHigher_Number_of_Hours_3days_150_Day2(data) });
    socket.on('Lower_Number_of_Hours_3days_150_Day2', (data) => { setLower_Number_of_Hours_3days_150_Day2(data) });
    socket.on('Double_drive_3days_150_Day2', (data) => { setDouble_drive_3days_150_Day2(data) });
    socket.on('Trucks_3days_150_Day2', (data) => { setTrucks_3days_150_Day2(data) });
    socket.on('Truck_fee_3days_150_Day2', (data) => { setTruck_fee_3days_150_Day2(data) });
    socket.on('Rate_cash_3days_150_Day2', (data) => { setRate_cash_3days_150_Day2(data) });
    socket.on('Rate_card_3days_150_Day2', (data) => { setRate_card_3days_150_Day2(data) });
    socket.on('Total_Destinationfee_3days_150_Day2', (data) => { setTotal_Destinationfee_3days_150_Day2(data) });
    socket.on('Movers_3days_150_Day2', (data) => { setMovers_3days_150_Day2(data) });
    socket.on('Round_trip_miles', (data) => { setRound_trip_miles(data) });
    socket.on('Truckloads_to_move', (data) => { setTruckloads_to_move(data) });
    socket.on('Combined_mileage', (data) => { setCombined_mileage(data) });
    socket.on('Truckloads', (data) => { setTruckloads(data) });
    socket.on('Total_cubes_Within_Building', (data) => { setTotal_cubes_Within_Building(data) });
    socket.on('Packing_Charge', (data) => { setPacking_Charge(data) });
    socket.on('Packing_kit_total_amount_with_taxes', (data) => { setPacking_kit_total_amount_with_taxes(data) });
    socket.on('Packing_kit_Show', (data) => { setPacking_kit_Show(data) });
    socket.on('Higher_Number_of_Hours_3days_150_Day1', (data) => { setHigher_Number_of_Hours_3days_150_Day1(data) });
    socket.on('Lower_Number_of_Hours_3days_150_Day1', (data) => { setLower_Number_of_Hours_3days_150_Day1(data) });
    socket.on('Double_drive', (data) => { setDouble_drive(data) });
    socket.on('Cubicf_3days_150_Day1', (data) => { setCubicf_3days_150_Day1(data) });
    socket.on('Trucks_To_Pack_3days_150_Day1', (data) => { setTrucks_To_Pack_3days_150_Day1(data) });
    socket.on('Truck_fee_3days_150_Day1', (data) => { setTruck_fee_3days_150_Day1(data) });
    socket.on('Rate_card_3days_150_Day1', (data) => { setRate_card_3days_150_Day1(data) });
    socket.on('Total_small_boxes', (data) => { setTotal_small_boxes(data); dispatch(setTotalSmallBoxes(data)); });
    socket.on('Total_medium_boxes', (data) => { setTotal_medium_boxes(data); dispatch(setTotalMediumBoxes(data)); });
    socket.on('Total_fragile_boxes', (data) => { setTotal_fragile_boxes(data); dispatch(setTotalFragileBoxes(data)); });
    socket.on('Total_box_cubes', (data) => { setTotal_box_cubes(data) });
    socket.on('Time_to_pack', (data) => { setTime_to_pack(data) });
    socket.on('Stop_1_Truckload', (data) => { setStop_1_Truckload(data) });
    socket.on('Stop_CN_Truckload', (data) => { setStop_CN_Truckload(data) });
    socket.on('Loading_hours_3days_150_Day1', (data) => { setLoading_hours_3days_150_Day1(data) });
    socket.on('Driving_Time_3days_150_Day1', (data) => { setDriving_Time_3days_150_Day1(data) });
    socket.on('Unloading_hours_3days_150_Day1', (data) => { setUnloading_hours_3days_150_Day1(data) });
    socket.on('Packing_Time_3days_150_Day1', (data) => { setPacking_Time_3days_150_Day1(data) });
    socket.on('Wrapping_time_3days_150_Day1', (data) => { setWrapping_time_3days_150_Day1(data) });
    socket.on('Total_Hours_3days_150_Day1', (data) => { setTotal_Hours_3days_150_Day1(data) });
    socket.on('Total_Cash_3days_150_Day1', (data) => { setTotal_Cash_3days_150_Day1(data) });
    socket.on('Total_Card_3days_150_Day1', (data) => { setTotal_Card_3days_150_Day1(data) });
    socket.on('Loading_hours_3days_150_Day2', (data) => { setLoading_hours_3days_150_Day2(data) });
    socket.on('Driving_time_with_DDT_3days_150_Day2', (data) => { setDriving_time_with_DDT_3days_150_Day2(data) });
    socket.on('Total_Hours_3days_150_Day2', (data) => { setTotal_Hours_3days_150_Day2(data) });
    socket.on('Total_Cash_3days_150_Day2', (data) => { setTotal_Cash_3days_150_Day2(data) });
    socket.on('Total_Card_3days_150_Day2', (data) => { setTotal_Card_3days_150_Day2(data) });
    socket.on('Unloading_hours_3days_150_Day3', (data) => { setUnloading_hours_3days_150_Day3(data) });
    socket.on('Driving_time_with_DDT_3days_150_Day3', (data) => { setDriving_time_with_DDT_3days_150_Day3(data) });
    socket.on('Total_Hours_3days_150_Day3', (data) => { setTotal_Hours_3days_150_Day3(data) });
    socket.on('Total_Cash_3days_150_Day3', (data) => { setTotal_Cash_3days_150_Day3(data) });
    socket.on('Total_Card_3days_150_Day3', (data) => { setTotal_Card_3days_150_Day3(data) });
    socket.on('Movers_To_Pack_3days_150_Day1', (data) => { setMovers_To_Pack_3days_150_Day1(data) });
    socket.on('Total_Destinationfee_3days_150', (data) => { setTotal_Destinationfee_3days_150(data) });
    socket.on('Rate_cash_3days_150_Day1', (data) => { setRate_cash_3days_150_Day1(data) });
  }, [templateForm.templateCalcObj])



  useEffect(() => {
    if (!didMount.current) {
      return didMount.current = true;
    }
    onFormValueChange(`templateEditorState`, 'set', JSON.stringify(convertToRaw(editorState.getCurrentContent())));
  }, [editorState]);

  useEffect(() => {
    try {
      if (updateTempState) {
        setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(templateEditorState))))
        setUpdateTempState(false)
      }
    }
    catch (err) {
      console.log(err)
    }
  }, [updateTempState]);


  const [selectState, setSelectState] = useState();

  const handleChange = (e) => {
    setSelectState(e.target.value)
  }
  const handleInsert = (evt) => {
    evt.preventDefault();
    setEditorState(insertText(selectState, editorState));
  }

  const handlePreview = (evt) => {
    evt.preventDefault();
    setShown(!popUpShown)
    let templateText = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    setPreviewText(replaceTagsByValues(templateText, stateTagValues))
  }

  const [popUpShown, setShown] = useState(false)
  const colorStyleMap = {
    blue: {
      color: 'blue',
    },
    blue: {
      color: 'blue',
    },
    // Add more color styles as needed
  };
  const insertText = (text, editorState) => {
    const currentContent = editorState.getCurrentContent(),
      currentSelection = editorState.getSelection();
    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      text
    );
    const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');
    return EditorState.forceSelection(newEditorState, newContent.getSelectionAfter());
  }

  const [previewText, setPreviewText] = useState('');
  const [optionsPreviewText, setOptionsPreviewText] = useState('');

  const handlePastedText = (event) => {
    // Get the pasted text
    const pastedText = event.clipboardData?.getData('text/plain') || window.clipboardData?.getData('Text');

    // Replace hard line breaks with soft line breaks
    if (pastedText) {
      const softBreakText = pastedText.replace(/[\r\n]+/g, '\n');

      // Modify the editorState with the updated content
      const contentState = Modifier.insertText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        softBreakText
      );
      const newEditorState = EditorState.push(editorState, contentState, 'insert-fragment');
      setEditorState(newEditorState);
    }
    try {
      event.preventDefault(); // Prevent the default paste behavior
    } catch (err) { }
  };

  useEffect(() => {
    if (company && Object.keys(company).length) {

      let templateText = draftToHtml(convertToRaw(editorState.getCurrentContent()))

      switch (templateForm.templateCalcType) {
        case 'oneDayPacking':
          socket.emit('getFormulasValues', { calculationType: 'oneDayPacking', commonValues: calculationForm.commonValues, oneDayPacking: calculationForm.oneDayPacking, department: calculationForm.department })
          break;

        case 'twoDaysPacking':
          socket.emit('getFormulasValues', { calculationType: 'twoDaysPacking', commonValues: calculationForm.commonValues, twoDaysPacking: calculationForm.twoDaysPacking, department: calculationForm.department })
          break;

        case 'twoDays150Miles':
          socket.emit('getFormulasValues', { calculationType: 'twoDays150Miles', commonValues: calculationForm.commonValues, twoDays150Miles: calculationForm.twoDays150Miles, department: calculationForm.department })
          break;

        case 'longOneDayPacking':
          socket.emit('getFormulasValues', { calculationType: 'longOneDayPacking', commonValues: calculationForm.commonValues, longOneDayPacking: calculationForm.longOneDayPacking, department: calculationForm.department, unpDepartment: calculationForm.unpDepartment, longDistance: calculationForm.longDistance })
          break;

        case 'longTwoDaysPacking':
          socket.emit('getFormulasValues', { calculationType: 'longTwoDaysPacking', commonValues: calculationForm.commonValues, longTwoDaysPacking: calculationForm.longTwoDaysPacking, department: calculationForm.department, unpDepartment: calculationForm.unpDepartment, longDistance: calculationForm.longDistance })
          break;

        case 'oos1day':
          socket.emit('getFormulasValues', { calculationType: 'oos1day', commonValues: calculationForm.commonValues, oos1day: calculationForm.oos1day, department: calculationForm.department, unpDepartment: calculationForm.unpDepartment, outOfState: calculationForm.outOfState })
          break;

        case 'oos2days':
          socket.emit('getFormulasValues', { calculationType: 'oos2days', commonValues: calculationForm.commonValues, oos2days: calculationForm.oos2days, department: calculationForm.department, unpDepartment: calculationForm.unpDepartment, outOfState: calculationForm.outOfState })
          break;

        default:
          break;
      }

      let tagValues = getTagsValues({
        calculation: templateForm.templateCalcObj,
        tags,
        calculatedParams: {
          Total_cubes: Total_cubes,
          Total_small_boxes: Total_small_boxes,
          Total_medium_boxes: Total_medium_boxes,
          Total_fragile_boxes: Total_fragile_boxes,
          Total_box_cubes: Total_box_cubes,
          Stop_1_Truckload: Stop_1_Truckload,
          Stop_CN_Truckload: Stop_CN_Truckload,
          Charge_per_mile_amount: Charge_per_mile_amount,
          Movers_to_load_One_Day_hours: Movers_to_load_One_Day_hours,
          Loading_hours_One_Day: Loading_hours_One_Day,
          Adjusted_loading_time_One_Day: Adjusted_loading_time_One_Day,
          Drivetime_One_Day: Drivetime_One_Day,
          Movers_to_unload_One_Day_hours: Movers_to_unload_One_Day_hours,
          Unloading_hours_One_Day: Unloading_hours_One_Day,
          Total_Hours_One_Day: Total_Hours_One_Day,
          Truckloads: Truckloads,
          Truckloads_to_move: Truckloads_to_move,
          Total_cubes_Within_Building: Total_cubes_Within_Building,
          Total_Destinationfee_One_Day: Total_Destinationfee_One_Day,
          Rate_cash_Movers_To_Load_One_Day: Rate_cash_Movers_To_Load_One_Day,
          Rate_card_Movers_To_Load_One_Day: Rate_card_Movers_To_Load_One_Day,
          Truck_fee_One_Day: Truck_fee_One_Day,
          Movers_One_Day: Movers_One_Day,
          Rate_cash_One_Day: Rate_cash_One_Day,
          Rate_card_One_Day: Rate_card_One_Day,
          Rate_cash_Movers_To_Unload_One_Day: Rate_cash_Movers_To_Unload_One_Day,
          Rate_card_Movers_To_Unload_One_Day: Rate_card_Movers_To_Unload_One_Day,
          Trucks_One_Day: Trucks_One_Day,
          TWO_Trips_Or_OK: TWO_Trips_Or_OK,
          BIGGEST_or_OK: BIGGEST_or_OK,
          Double_drive: Double_drive,
          Lower_Number_of_Hours_One_Day: Lower_Number_of_Hours_One_Day,
          Higher_Number_of_Hours_One_Day: Higher_Number_of_Hours_One_Day,
          Heavy_fee: Heavy_fee,
          Packing_kit_Show: Packing_kit_Show,
          Packing_kit_total_amount_with_taxes: Packing_kit_total_amount_with_taxes,
          Total_Card_One_day: Total_Card_One_day,
          Total_Cash_One_day: Total_Cash_One_day,
          Trucks_Unpacking_Separate_Day: Trucks_Unpacking_Separate_Day,
          Movers_Unpacking_Separate_Day: Movers_Unpacking_Separate_Day,
          Unpacking_Separate_Day_Lower_hours: Unpacking_Separate_Day_Lower_hours,
          Unpacking_Separate_Day_Higher_hours: Unpacking_Separate_Day_Higher_hours,
          Total_Card_Unpacking_Separate_Day: Total_Card_Unpacking_Separate_Day,
          Total_Cash_Unpacking_Separate_Day: Total_Cash_Unpacking_Separate_Day,
          Rate_cash_Unpacking_Separate_Day: Rate_cash_Unpacking_Separate_Day,
          Rate_card_Unpacking_Separate_Day: Rate_card_Unpacking_Separate_Day,
          Truck_fee_Unpacking_Separate_Day: Truck_fee_Unpacking_Separate_Day,
          Combined_mileage: Combined_mileage,
          TWO_Trips_Or_OK_Day2: TWO_Trips_Or_OK_Day2,
          Loading_hours_150miles: Loading_hours_150miles,
          Total_Hours_150miles_Day1: Total_Hours_150miles_Day1,
          Unloading_hours_150miles: Unloading_hours_150miles,
          Total_Hours_150miles_Day2: Total_Hours_150miles_Day2,
          Movers_150miles_Day1: Movers_150miles_Day1,
          Movers_150miles_Day2: Movers_150miles_Day2,
          Total_Destinationfee_150miles: Total_Destinationfee_150miles,
          Double_drive_150miles_Day1: Double_drive_150miles_Day1,
          Lower_Number_of_Hours_150miles_Day1: Lower_Number_of_Hours_150miles_Day1,
          Higher_Number_of_Hours_150miles_Day2: Higher_Number_of_Hours_150miles_Day2,
          Higher_Number_of_Hours_150miles_Day1: Higher_Number_of_Hours_150miles_Day1,
          Trucks_150miles_Day2: Trucks_150miles_Day2,
          Double_drive_150miles_Day2: Double_drive_150miles_Day2,
          Lower_Number_of_Hours_150miles_Day2: Lower_Number_of_Hours_150miles_Day2,
          Total_Card_150miles_2Days: Total_Card_150miles_2Days,
          Total_Cash_150miles_Day2: Total_Cash_150miles_Day2,
          Total_Cash_150miles_2Days: Total_Cash_150miles_2Days,
          Driving_time_with_DDT_150miles_Day1: Driving_time_with_DDT_150miles_Day1,
          Driving_time_with_DDT_150miles_Day2: Driving_time_with_DDT_150miles_Day2,
          Rate_cash_150miles_Day1: Rate_cash_150miles_Day1,
          Total_Cash_150miles_Day1: Total_Cash_150miles_Day1,
          Total_Card_150miles_Day1: Total_Card_150miles_Day1,
          Total_Card_150miles_Day2: Total_Card_150miles_Day2,
          Rate_card_150miles_Day1: Rate_card_150miles_Day1,
          Trucks_150miles_Day1: Trucks_150miles_Day1,
          Destination_fee_150miles: Destination_fee_150miles,
          Truck_fee_150miles_Day1: Truck_fee_150miles_Day1,
          Rate_cash_150miles_Day2: Rate_cash_150miles_Day2,
          Rate_card_150miles_Day2: Rate_card_150miles_Day2,
          Truck_fee_150miles_Day2: Truck_fee_150miles_Day2,
          Movers_To_Pack_Day1: Movers_To_Pack_Day1,
          Trucks_To_Pack_Day1: Trucks_To_Pack_Day1,
          Driving_Time_Day1: Driving_Time_Day1,
          Unloading_hours_Day1: Unloading_hours_Day1,
          Loading_hours_Day_1: Loading_hours_Day_1,
          Total_Hours_Day_1: Total_Hours_Day_1,
          Packing_Time_Day1: Packing_Time_Day1,
          Wrapping_time_Day1: Wrapping_time_Day1,
          Adjusted_loading_time_Day2: Adjusted_loading_time_Day2,
          Lower_Number_of_Hours_Day1: Lower_Number_of_Hours_Day1,
          Higher_Number_of_Hours_Day1: Higher_Number_of_Hours_Day1,
          Lower_Number_of_Hours_Day2: Lower_Number_of_Hours_Day2,
          Higher_Number_of_Hours_Day2: Higher_Number_of_Hours_Day2,
          Total_Cash_Day2: Total_Cash_Day2,
          Total_Card_Day2: Total_Card_Day2,
          Loading_hours_Day2: Loading_hours_Day2,
          Movers_Day2: Movers_Day2,
          Rate_cash_Movers_To_Unload_Day2: Rate_cash_Movers_To_Unload_Day2,
          Rate_card_Movers_To_Unload_Day2: Rate_card_Movers_To_Unload_Day2,
          Total_Destinationfee_Day2: Total_Destinationfee_Day2,
          Rate_cash_Movers_To_Load_Day2: Rate_cash_Movers_To_Load_Day2,
          Rate_card_Movers_To_Load_Day2: Rate_card_Movers_To_Load_Day2,
          Truck_fee_Day2: Truck_fee_Day2,
          Rate_cash_Day2: Rate_cash_Day2,
          Rate_card_Day2: Rate_card_Day2,
          Rate_cash_Day1: Rate_cash_Day1,
          Rate_card_Day1: Rate_card_Day1,
          Truck_fee_Day1: Truck_fee_Day1,
          Trucks_Day2: Trucks_Day2,
          Unloading_hours_Day2: Unloading_hours_Day2,
          Total_Hours_Day2: Total_Hours_Day2,
          Drivetime_Day2: Drivetime_Day2,
          cubicf_Day1: cubicf_Day1,
          Total_Cash_Day1: Total_Cash_Day1,
          Total_Card_Day1: Total_Card_Day1,
          Total_Card_2Days_Packing: Total_Card_2Days_Packing,
          Total_Cash_2Days_Packing: Total_Cash_2Days_Packing,
          BIGGEST_or_OK_Day2: BIGGEST_or_OK_Day2,
          Total_Hours_LONGOne_Day_Loading,
          Drivetime_LONGOne_Day: Drivetime_LONGOne_Day,
          Movers_LONGOne_Day_Loading: Movers_LONGOne_Day_Loading,
          Trucks_LONGOne_Day: Trucks_LONGOne_Day,
          Total_Hours_LONGOne_Day_Loading_Lower_number: Total_Hours_LONGOne_Day_Loading_Lower_number,
          Total_Hours_LONGOne_Day_Unloading: Total_Hours_LONGOne_Day_Unloading,
          Movers_LONGOne_Day_Unloading: Movers_LONGOne_Day_Unloading,
          Total_Hours_LONGOne_Day_Unloading_Lower_number: Total_Hours_LONGOne_Day_Unloading_Lower_number,
          Total_Card_LONGOne_Day: Total_Card_LONGOne_Day,
          Total_Cash_LONGOne_Day: Total_Cash_LONGOne_Day,
          Total_Card_LONGOne_Day_R: Total_Card_LONGOne_Day_R,
          Total_Cash_LONGOne_Day_R: Total_Cash_LONGOne_Day_R,
          Total_Card_Unpacking_Separate_Day_Long_and_OOS: Total_Card_Unpacking_Separate_Day_Long_and_OOS,
          Movers_To_Pack_LONG_Day1: Movers_To_Pack_LONG_Day1,
          Rate_cash_LONG_Day1: Rate_cash_LONG_Day1,
          Rate_card_LONG_Day1: Rate_card_LONG_Day1,
          Truck_fee_LONG_Day1: Truck_fee_LONG_Day1,
          Packing_Time_LONG_Day1: Packing_Time_LONG_Day1,
          Wrapping_time_LONG_Day1: Wrapping_time_LONG_Day1,
          Total_Hours_LONG_Day1: Total_Hours_LONG_Day1,
          Lower_Number_of_Hours_LONG_Day1: Lower_Number_of_Hours_LONG_Day1,
          Higher_Number_of_Hours_LONG_Day1: Higher_Number_of_Hours_LONG_Day1,
          Total_Card_LONG_Day1_Rounded: Total_Card_LONG_Day1_Rounded,
          Total_Card_LONG_Day1: Total_Card_LONG_Day1,
          Movers_LONG2DAYS_Loading: Movers_LONG2DAYS_Loading,
          Drivetime_LONG2DAYS: Drivetime_LONG2DAYS,
          Total_Hours_LONG2DAYS_Unloading: Total_Hours_LONG2DAYS_Unloading,
          Trucks_LONG2DAYS: Trucks_LONG2DAYS,
          Total_Hours_LONG2DAYS_Loading_Lower_number: Total_Hours_LONG2DAYS_Loading_Lower_number,
          Total_Hours_LONG2DAYS_Loading: Total_Hours_LONG2DAYS_Loading,
          Movers_LONG2DAYS_Unloading: Movers_LONG2DAYS_Unloading,
          Total_Hours_LONG2DAYS_Unloading_Lower_number: Total_Hours_LONG2DAYS_Unloading_Lower_number,
          Total_Card_LONG2DAYS: Total_Card_LONG2DAYS,
          Total_Card_LONG_Day2: Total_Card_LONG_Day2,
          Total_Card_LONG_Day2_R: Total_Card_LONG_Day2_R,
          Total_Card_LONG2DAYS_R: Total_Card_LONG2DAYS_R,
          Total_Cash_LONG2DAYS: Total_Cash_LONG2DAYS,
          Total_Cash_LONG2DAYS_R: Total_Cash_LONG2DAYS_R,
          Total_Cash_LONG_Day1: Total_Cash_LONG_Day1,
          Movers_OOSOne_Day_Loading: Movers_OOSOne_Day_Loading,
          Rate_card_OOSOne_Day: Rate_card_OOSOne_Day,
          Total_Amount_Packing_OOSOne_Day: Total_Amount_Packing_OOSOne_Day,
          Packing_time_without_loading_OOSOne_Day: Packing_time_without_loading_OOSOne_Day,
          Total_Hours_OOSOne_Day_Unloading: Total_Hours_OOSOne_Day_Unloading,
          Trucks_OOSOne_Day: Trucks_OOSOne_Day,
          Total_Hours_OOSOne_Day_Loading_Lower_number: Total_Hours_OOSOne_Day_Loading_Lower_number,
          Drivetime_OOSOne_Day: Drivetime_OOSOne_Day,
          Movers_OOSOne_Day_Unloading: Movers_OOSOne_Day_Unloading,
          Total_Hours_OOSOne_Day_Unloading_Lower_number: Total_Hours_OOSOne_Day_Unloading_Lower_number,
          Total_Card_OOSOne_Day: Total_Card_OOSOne_Day,
          Total_Cash_OOSOne_Day: Total_Cash_OOSOne_Day,
          Total_Hours_OOSOne_Day_Loading: Total_Hours_OOSOne_Day_Loading,
          Rate_card_Unpacking_Same_Day_OOS: Rate_card_Unpacking_Same_Day_OOS,
          Unpacking_hours_same_day_OOSOne_Day: Unpacking_hours_same_day_OOSOne_Day,
          Total_Card_Unpacking_Same_Day_OOS: Total_Card_Unpacking_Same_Day_OOS,
          Movers_To_Pack_OOS_Day1: Movers_To_Pack_OOS_Day1,
          Rate_card_OOS_Day1: Rate_card_OOS_Day1,
          Packing_Time_OOS_Day1: Packing_Time_OOS_Day1,
          Wrapping_time_OOS_Day1: Wrapping_time_OOS_Day1,
          Total_Hours_OOS2DAYS_Loading: Total_Hours_OOS2DAYS_Loading,
          Total_Hours_OOS_Day1: Total_Hours_OOS_Day1,
          Lower_Number_of_Hours_OOS_Day1: Lower_Number_of_Hours_OOS_Day1,
          Higher_Number_of_Packing_Hours_OOS_Day1: Higher_Number_of_Packing_Hours_OOS_Day1,
          Higher_Number_of_Hours_OOS_Day1: Higher_Number_of_Hours_OOS_Day1,
          Total_Card_Packing_OOS_Day1: Total_Card_Packing_OOS_Day1,
          Total_Card_Packing_OOS_Day1_Rounded: Total_Card_Packing_OOS_Day1_Rounded,
          Movers_OOS2DAYS_Loading: Movers_OOS2DAYS_Loading,
          Total_Hours_OOS2DAYS_Unloading: Total_Hours_OOS2DAYS_Unloading,
          Total_Card_OOS2DAYS: Total_Card_OOS2DAYS,
          Total_Amount_MilesFactor_OOS2DAYS: Total_Amount_MilesFactor_OOS2DAYS,
          Trucks_OOS2DAYS: Trucks_OOS2DAYS,
          Total_Hours_OOS2DAYS_Loading_Lower_number: Total_Hours_OOS2DAYS_Loading_Lower_number,
          Total_Hours_OOS2DAYS_Unloading_Lower_number: Total_Hours_OOS2DAYS_Unloading_Lower_number,
          Total_Cash_OOS2DAYS: Total_Cash_OOS2DAYS,
          Rate_card_Unpacking_Same_Day_OOS_2Days: Rate_card_Unpacking_Same_Day_OOS_2Days,
          Unpacking_hours_same_day_OOS2DAYS: Unpacking_hours_same_day_OOS2DAYS,
          Total_Card_Unpacking_Same_Day_OOS_2Days: Total_Card_Unpacking_Same_Day_OOS_2Days,
          Trucks_To_Pack_OOS_Day1: Trucks_To_Pack_OOS_Day1,
          Flat_rate_One_Day: Flat_rate_One_Day,
          Flat_rate_One_Day_cash: Flat_rate_One_Day_cash,

          Total_small_boxes,
          Total_medium_boxes,
          Total_fragile_boxes,
          Total_box_cubes,
          Time_to_pack,
          Stop_1_Truckload,
          Stop_CN_Truckload,
          Loading_hours_3days_150_Day1,
          Driving_Time_3days_150_Day1,
          Unloading_hours_3days_150_Day1,
          Packing_Time_3days_150_Day1,
          Wrapping_time_3days_150_Day1,
          Total_Hours_3days_150_Day1,
          Total_Cash_3days_150_Day1,
          Total_Card_3days_150_Day1,
          Loading_hours_3days_150_Day2,
          Driving_time_with_DDT_3days_150_Day2,
          Total_Hours_3days_150_Day2,
          Total_Cash_3days_150_Day2,
          Total_Card_3days_150_Day2,
          Unloading_hours_3days_150_Day3,
          Driving_time_with_DDT_3days_150_Day3,
          Total_Hours_3days_150_Day3,
          Total_Cash_3days_150_Day3,
          Total_Card_3days_150_Day3,
          Movers_To_Pack_3days_150_Day1,
          Total_Destinationfee_3days_150,
          Rate_cash_3days_150_Day1,
          Rate_card_3days_150_Day1,
          Truck_fee_3days_150_Day1,
          Trucks_To_Pack_3days_150_Day1,
          cubicf_3days_150_Day1,
          Double_drive,
          Lower_Number_of_Hours_3days_150_Day1,
          Higher_Number_of_Hours_3days_150_Day1,
          Packing_kit_Show,
          Packing_kit_total_amount_with_taxes,
          Packing_Charge,
          Total_cubes,
          Total_cubes_Within_Building,
          Truckloads,
          Combined_mileage,
          Truckloads_to_move,
          Round_trip_miles,
          Movers_3days_150_Day2,
          Total_Destinationfee_3days_150_Day2,
          Rate_card_3days_150_Day2,
          Rate_cash_3days_150_Day2,
          Truck_fee_3days_150_Day2,
          Trucks_3days_150_Day2,
          Double_drive_3days_150_Day2,
          Lower_Number_of_Hours_3days_150_Day2,
          Higher_Number_of_Hours_3days_150_Day2,
          Movers_3days_150_Day3,
          Rate_cash_3days_150_Day3,
          Rate_card_3days_150_Day3,
          Truck_fee_3days_150_Day3,
          Trucks_3days_150_Day3,
          BIGGEST_or_OK,
          TWO_Trips_Or_OK_3days_150_Day3,
          Double_drive_3days_150_Day3,
          Destination_fee_3days_150_Day3,
          Lower_Number_of_Hours_3days_150_Day3,
          Higher_Number_of_Hours_3days_150_Day3,
          Heavy_fee,
          Flat_rate_Cash_3days_150_Day3,
          Flat_rate_Card_3days_150_Day3,
          Trucks_Unpacking_Separate_Day,
          Movers_Unpacking_Separate_Day,
          Unpacking_Separate_Day_Lower_hours,
          Unpacking_Separate_Day_Higher_hours,
          Total_Card_Unpacking_Separate_Day,
          Total_Cash_Unpacking_Separate_Day,
          Rate_cash_Unpacking_Separate_Day,
          Rate_card_Unpacking_Separate_Day,
          Truck_fee_Unpacking_Separate_Day,
          Flat_rate_Unpacking_Separate_Day,
          Flat_rate_Unpacking_Separate_Day_cash,
          Total_Card_threeDays150Miles,
          Total_Cash_threeDays150Miles,
          UnPacking_Charge
        }, setTagsResultsTexts, tagsResultsTexts, furniture, roomsAreas
      })
      setPreviewText(replaceTagsByValues(templateText, tagValues))
      setOptionsPreviewText(replaceOptionsTagsByValues(templateText, tagValues))
      setStateTagValues(tagValues)
    }


  }, [templateForm.templateCalcObj, popUpShown]);

  return (
    <div className={styles.templateContainer}>
      <div className={styles.selectD} >
        <span style={{ marginLeft: '3px', marginRight: '3px' }}>  Insert</span>
        <select onChange={handleChange} value={selectState} className={`${styles.firstSelect} ${styles.custom_select}`}>
          <option value=''>None</option>
          <option value='|{optStart}'>Option start</option>
          <option value='|{optEnd}'>Option end</option>
          <option value='|{inventory}'>Inventory</option>
          <option value='|{stays}'>Stays</option>
          {tags.map((tag, index) => {
            if (tag[`tagName`] !== '') {
              return <option
                value={'|{' + tag[`tagName`] + '}'}
                key={tag[`tagName`]}>
                {tag[`tagName`]}
              </option>
            }
          })}
        </select>
        <span style={{ marginLeft: '3px', marginRight: '3px' }}> (where cursor is)</span>

        <button onClick={handleInsert} className={styles.btn}>Insert</button>
        <button onClick={handlePreview} style={{ marginLeft: "10px" }} className={styles.btn}>Preview</button>
        <button onClick={evt => saveBtnEvent(evt, true)} className={styles.topSaveBtn} disabled={templateSavingStatus === 'loading'}>Save</button>
      </div>

      <TemplatePreviewPopup
        popUpShown={popUpShown}
        previewText={previewText}
        setShown={setShown}
        optionsPreviewText={optionsPreviewText}
      />


      <Editor
        editorState={editorState}
        editorClassName={styles.editorStyle}
        toolbarClassName={styles.customToolbar}
        key={JSON.stringify(editorState)}
        onEditorStateChange={setEditorState}
        toolbar={{
          options: ['inline', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'history', 'remove'],
          inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['bold', 'italic', 'underline', 'strikethrough'],
          },
          fontFamily: {
            options: FontFamiliesOptions,
          },
          colorPicker: {
            colors: [
              "rgb(0, 0, 0)",
              "rgb(68, 68, 68)",
              "rgb(102, 102, 102)",
              "rgb(153, 153, 153)",
              "rgb(204, 204, 204)",
              "rgb(238, 238, 238)",
              "rgb(243, 243, 243)",
              "rgb(255, 255, 255)",

              "rgb(255, 0, 0)",
              "rgb(255, 153, 0)",
              "rgb(255, 255, 0)",
              "rgb(0, 255, 0)",
              "rgb(0, 255, 255)",
              "rgb(0, 0, 255)",
              "rgb(153, 0, 255)",
              "rgb(255, 0, 255)",


              "rgb(244,204,204)",
              "rgb(252,229,205)",
              "rgb(255,242,204)",
              "rgb(217,234,211)",
              "rgb(208,224,227)",
              "rgb(207,226,243)",
              "rgb(217,210,233)",
              "rgb(234,209,220)",

              "rgb(234,153,153)",
              "rgb(249,203,156)",
              "rgb(255,229,153)",
              "rgb(182,215,168)",
              "rgb(162,196,201)",
              "rgb(159,197,232)",
              "rgb(180,167,214)",
              "rgb(213,166,189)",

              "rgb(224,102,102)",
              "rgb(246,178,107)",
              "rgb(255,217,102)",
              "rgb(147,196,125)",
              "rgb(118,165,175)",
              "rgb(111,168,220)",
              "rgb(142,124,195)",
              "rgb(194,123,160)",

              "rgb(204,0,0)",
              "rgb(230,145,56)",
              "rgb(241,194,50)",
              "rgb(106,168,79)",
              "rgb(69,129,142)",
              "rgb(61,133,198)",
              "rgb(103,78,167)",
              "rgb(166,77,121)",

              "rgb(153,0,0)",
              "rgb(180,95,6)",
              "rgb(191,144,0)",
              "rgb(56,118,29)",
              "rgb(19,79,92)",
              "rgb(11,83,148)",
              "rgb(53,28,117)",
              "rgb(116,27,71)",

              "rgb(102,0,0)",
              "rgb(120,63,4)",
              "rgb(127,96,0)",
              "rgb(39,78,19)",
              "rgb(12,52,61)",
              "rgb(7,55,99)",
              "rgb(32,18,77)",
              "rgb(76,17,48)"
            ],
          },
        }}

        handleReturn={(event) => {
          const currentContent = editorState.getCurrentContent();
          const currentSelection = editorState.getSelection();
          const currentBlock = currentContent.getBlockForKey(currentSelection.getStartKey());
          const blockType = currentBlock.getType();

          if (blockType === 'unordered-list-item' || blockType === 'ordered-list-item') {
            if (event.keyCode === 13 && event.shiftKey) {
              // with shift, insert a soft newline
              const newContent = Modifier.insertText(currentContent, currentSelection, '\n');
              const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');
              setEditorState(newEditorState);
              return true;
            }
          } else {
            if (event.keyCode === 13 && event.shiftKey) {
              const newEditorState = RichUtils.insertSoftNewline(editorState);
              setEditorState(newEditorState);
              return true;
            } else if (event.keyCode === 13 && !event.shiftKey) {
              const newEditorState = RichUtils.insertSoftNewline(editorState);
              setEditorState(newEditorState);
              return true;
            }
          }
          return false;
        }}
        onPaste={handlePastedText}
      />
      {console.log('templateSavingStatus', templateSavingStatus)}
      <BtnSubmit
        isActive={templateSavingStatus !== 'loading'}
        action={evt => saveBtnEvent(evt, true)}
        name={'Save'}
      />
    </div>
  );
};
